export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const EDIT_CUSTOMER_MIDDLEWARE = "EDIT_CUSTOMER_MIDDLEWARE";
export const EDIT_CUSTOMER_DATA = "EDIT_CUSTOMER_DATA";
export const ADDING_NEW_CUSTOMER = "ADDING_NEW_CUSTOMER";
export const NEW_CUSTOMER_ADDED = "NEW_CUSTOMER_ADDED";
export const ADD_NEW_CUSTOMER_ERROR = "ADD_NEW_CUSTOMER_ERROR";

export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GETTING_ALL_CUSTOMERS = "GETTING_ALL_CUSTOMERS";
export const SET_ALL_CUSTOMERS = "SET_ALL_CUSTOMERS";
export const GET_ALL_CUSTOMERS_ERROR = "GET_ALL_CUSTOMERS_ERROR";

export const ADD_NEW_CUSTOMER_JOB = "ADD_NEW_CUSTOMER_JOB";
export const ADDING_NEW_CUSTOMER_JOB = "ADDING_NEW_CUSTOMER_JOB";
export const NEW_CUSTOMER_JOB_ADDED = "NEW_CUSTOMER_JOB_ADDED";
export const ADD_NEW_CUSTOMER_JOB_ERROR = "ADD_NEW_CUSTOMER_JOB_ERROR";
export const GET_DEVICE_DATA = "GET_DEVICE_DATA"

export const SET_ALL_DEVICE = "SET_ALL_DEVICE"
export const REMOVE_DEVICE_FROM_CUSTOMER = "REMOVE_DEVICE_FROM_CUSTOMER"
export const REMOVE_DEVICE_FROM_CUSTOMER_MIDDLEWARE = "REMOVE_DEVICE_FROM_CUSTOMER_MIDDLEWARE"
