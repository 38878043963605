import {
    GETTING_ALL_JOBS,
    SET_ALL_JOBS,
    GET_ALL_JOBS_ERROR,
    UPDATE_JOB_SUCCESS,
    SET_NOTIFICATION_JOB,
    ADD_EVIDENCE_JOB1,
    GET_EVIDENCE_JOB_MIDDLEWARE,
    LOADER,
    ADD_EVIDENCE_JOB_ERROR,
    ADD_EVIDENCE_JOB_SUCCESS,
    LANGUAGE_CHANGES
} from "./types";
import get from "lodash/get";

const initialState = {
    allJobs: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1,
    },
    notificationJob: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
    },
    jobEvidence: {},
    apiCalledSuccessfully: false,
    loader: false,
    success: false,
    languageChanges: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GETTING_ALL_JOBS:
            return {
                ...state,
                allJobs: { ...state.allJobs, loading: true },
            };
        case SET_ALL_JOBS:
            const allJobsList = get(action.payload, "list", []);
            const lastPage = get(action.payload, "lastPage", 1);
            const requestedPage = get(action.payload, "requestedPage", 1);
            const tableData = allJobsList.map((job) => ({
                ...job,
                // name: `${admin.firstName} `,
                // creationDate: moment(admin.creationDate).format("DD/MM/YYYY"),
            }));

            const allData =
                // requestedPage === 1
                //     ?
                tableData;
            // : [...state.allJobs.data, ...tableData];

            return {
                ...state,
                allJobs: {
                    ...state.allJobs,
                    loading: false,
                    data: allData,
                    lastPage: lastPage,
                },
            };
        case GET_ALL_JOBS_ERROR:
            return {
                ...state,
                allJobs: { ...state.allJobs, loading: false },
            };
        case UPDATE_JOB_SUCCESS:
            let prevData = [...state.allJobs.data];
            const toRemove = get(action.payload, "index.remove", false);
            const rowJobId = get(action.payload, "index.rowJobId", "");
            const type = get(action.payload, "type", false);

            const jobIndexToUpdate = prevData.findIndex(
                (job) => job.jobId === rowJobId
            );
            if (jobIndexToUpdate !== -1) {
                if (toRemove) {
                    prevData.splice(jobIndexToUpdate, 1);
                } else if (toRemove === false) {
                    prevData = prevData.map((job, jobIndex) => {
                        if (jobIndex !== jobIndexToUpdate) return job;
                        else {
                            job.status = type;
                            return job;
                        }
                    });
                }
            }
            return {
                ...state,
                allJobs: { ...state.allJobs, data: prevData },
            };
        case SET_NOTIFICATION_JOB:
            return {
                ...state,
                notificationJob: {
                    ...state.notificationJob,
                    data: action.payload,
                },
            };

        case ADD_EVIDENCE_JOB1:
            return {
                ...state,
                apiCalledSuccessfully: true,
            };
        case ADD_EVIDENCE_JOB_ERROR:
            return {
                ...state,
                success: false
            };
        case ADD_EVIDENCE_JOB_SUCCESS:
            return {
                ...state,
                success: true
            };
        case GET_EVIDENCE_JOB_MIDDLEWARE:
            return {
                ...state,
                apiCalledSuccessfully: false,
                jobEvidence: action.payload,
            };
        case LOADER:
            return {
                ...state,
                loader: action.payload
            };
        case LANGUAGE_CHANGES:
            return {
                ...state,
                languageChanges:true
            }
        default:
            return { ...state };
    }
};
