import {
    ADD_BRAND_BEGIN,
    ADD_BRAND_FAIL,
    ADD_BRAND_MIDDLEWARE,
    ADD_MODEL_BEGIN,
    ADD_MODEL_FAIL,
    ADD_PRODUCT_SERIES_BEGIN,
    ADD_PRODUCT_SERIES_FAIL,
    GET_BRAND_BEGIN,
    GET_BRAND_FAIL,
    GET_MODEL_BEGIN,
    GET_MODEL_FAIL,
    GET_PRODUCT_SERIES_BEGIN,
    GET_PRODUCT_SERIES_FAIL,
    RESET_STATE,
    GET_BRAND_MIDDLEWARE,
    ADD_PRODUCT_SERIES_MIDDLEWARE_SUCCESS,
    GET_PRODUCT_SERIES_MIDDLEWARE_SUCCESS,
    REMOVE_DATA,
    ADD_MODEL_MIDDLEWARE_SUCCESS,
    GET_MODEL_MIDDLEWARE_SUCCESS,
    EDIT_BRAND_SUCCESS,
    EDIT_PRODUCT_SERIES_SUCCESS,
    EDIT_MODEL_SUCCESS
} from "./types";

const initialState = {
    loading: false,
    success: false,
    status: "",
    brandLists: {},
    productLists: {},
    modelLists: {},
    errorMessage: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_BRAND_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                status: "start",
                errorMessage: "",
            }
        case ADD_BRAND_FAIL:
            return {
                ...state,
                loading: false,
                status: "fail",
                success: false,
                errorMessage: action.payload || "something wrong",
            }

        case ADD_BRAND_MIDDLEWARE:
            return {
                ...state,
                loading: false,
                status: "success",
                success: true,
                errorMessage: "",
            };

        case EDIT_BRAND_SUCCESS:
            let cloneData = [...state.brandLists.list]
            const ind = cloneData.findIndex(p => p.id === action.payload.id)
            cloneData[ind] = {
                ...cloneData[ind],
                ...action.payload
            }
            
            return {
                ...state,
                brandLists:{
                    ...state.brandLists,
                    list: cloneData
                },
                loading: false,
                status: "success",
                success: true,
                errorMessage: "",
            };
        case EDIT_PRODUCT_SERIES_SUCCESS:
            let cloneDataProduct = [...state.productLists.list]
            const index = cloneDataProduct.findIndex(p => p.id === action.payload.id)
            cloneDataProduct[index] = {
                ...cloneDataProduct[index],
                ...action.payload
            }
            return {
                ...state,
                productLists:{
                    ...state.productLists,
                    list: cloneDataProduct
                },
                loading: false,
                status: "success",
                success: true,
                errorMessage: "",
            };
        case EDIT_MODEL_SUCCESS:
            let cloneDataModel = [...state.modelLists.list]
            const indexModel = cloneDataModel.findIndex(p => p.id === action.payload.id)
            cloneDataModel[indexModel] = {
                ...cloneDataModel[indexModel],
                ...action.payload
            }
            return {
                ...state,
                modelLists:{
                    ...state.modelLists,
                    list: cloneDataModel
                },
                loading: false,
                status: "success",
                success: true,
                errorMessage: "",
            };
        case GET_BRAND_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                status: "start",
                errorMessage: "",
            }
        case GET_BRAND_FAIL:
            return {
                ...state,
                loading: false,
                status: "fail",
                success: false,
                errorMessage: action.payload || "something wrong",
            }

        case GET_BRAND_MIDDLEWARE:
            return {
                ...state,
                loading: false,
                status: "success",
                success: true,
                brandLists: action.payload,
                errorMessage: "",
            };
        case GET_PRODUCT_SERIES_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                status: "start",
                errorMessage: "",
            }
        case GET_PRODUCT_SERIES_FAIL:
            return {
                ...state,
                loading: false,
                status: "fail",
                success: false,
                errorMessage: action.payload || "something wrong",
            }

        case GET_PRODUCT_SERIES_MIDDLEWARE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                success: true,
                productLists: action.payload,
                errorMessage: "",
            };
        case GET_MODEL_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                status: "start",
                errorMessage: "",
            }
        case GET_MODEL_FAIL:
            return {
                ...state,
                loading: false,
                status: "fail",
                success: false,
                errorMessage: action.payload || "something wrong",
            }

        case GET_MODEL_MIDDLEWARE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                success: true,
                modelLists: action.payload,
                errorMessage: "",
            };
        case ADD_PRODUCT_SERIES_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                status: "start",
                errorMessage: "",
            }
        case ADD_PRODUCT_SERIES_FAIL:
            return {
                ...state,
                loading: false,
                status: "fail",
                success: false,
                errorMessage: action.payload || "something wrong",
            }

        case ADD_PRODUCT_SERIES_MIDDLEWARE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                success: true,
                errorMessage: "",
            };

        case ADD_MODEL_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                status: "start",
                errorMessage: "",
            }
        case ADD_MODEL_FAIL:
            return {
                ...state,
                loading: false,
                status: "fail",
                success: false,
                errorMessage: action.payload || "something wrong",
            }

        case ADD_MODEL_MIDDLEWARE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                success: true,
                errorMessage: "",
            };

        case RESET_STATE:
            return {
                ...state,
                loading: false,
                status: "",
                success: false,
                errorMessage: "",
            };

        case REMOVE_DATA:
            let CloneProductLists = { ...state.productLists }
            let CloneModelLists = { ...state.modelLists }
            action.payload === "product" ? CloneProductLists = {} : CloneModelLists = {}
            return {
                ...state,
                loading: false,
                status: "",
                success: false,
                errorMessage: "",
                productLists: CloneProductLists,
                modelLists: CloneModelLists
            };
        default:
            return { ...state };
    }
};
