import {
    ADD_TRAINING,
    SET_TRAINING,
    GET_ALL_TRAINING,
    ADD_CONTENT,
    SET_ALL_TRAINING,
    GET_ALL_CHAPTERS,
    SET_TRAINING_VIEW,
    SET_ALL_TRAINING_WITHOUT_FILTER,
    SET_ALL_CHAPTERS,
    GET_TRAINING_SERVER_PROVIDER_LISTS_REQUEST,
    GET_TRAINING_SERVER_PROVIDER_LISTS_MIDDLEWARE_SUCCESS,
    GET_TRAINING_SERVER_PROVIDER_LISTS_FAIL,
    GET_TRAINING_SERVER_PROVIDER_LISTS_PAYLOAD,
    GET_ALL_CONTENT_DATA,
    SET_ALL_CONTENT_DATA,
    SET_ALL_CONTENT_DATA_LOADING,
    GET_TRAINING_USER_LISTS_PAYLOAD,
    GET_TRAINING_USER_LISTS_REQUEST,
    GET_TRAINING_USER_LISTS_MIDDLEWARE_SUCCESS,
    GET_TRAINING_USER_LISTS_FAIL,
    REMOVE_CONTENT_DATA,
    GET_ALL_CONTENT_DATA_PAYLOAD,
    GET_ALL_CONTENT_DATA_REQUEST,
    GET_ALL_CONTENT_DATA_MIDDLEWARE_SUCCESS,
    GET_ALL_CONTENT_DATA_FAIL,
    UPDATE_TRAINING_STATUS,
    TRAINING_STATUS_UPDATE_SUCCESS,
} from "./types";

export const addTraining = (payload) => ({
    type: ADD_TRAINING,
    payload,
});

export const addContent = (payload) => ({
    type: ADD_CONTENT,
    payload,
});

export const setTraining = (payload) => ({
    type: SET_TRAINING,
    payload,
});

export const getAllTraining = (payload) => ({
    type: GET_ALL_TRAINING,
    payload,
});

export const setAllTraining = (payload) => ({
    type: SET_ALL_TRAINING,
    payload,
});

export const setAllTrainingWithoutFilter = (payload) => ({
    type: SET_ALL_TRAINING_WITHOUT_FILTER,
    payload,
});

export const getChapters = (payload) => ({
    type: GET_ALL_CHAPTERS,
    payload,
});

export const setChapters = (payload) => ({
    type: SET_ALL_CHAPTERS,
    payload,
});

export const setTrainingView = (payload) => ({
    type: SET_TRAINING_VIEW,
    payload,
});
export const getTrainingServiceProvider = (payload) => ({
    type: GET_TRAINING_SERVER_PROVIDER_LISTS_PAYLOAD,
    payload,
});
export const getTrainingServiceProviderBegin = (payload) => ({
    type: GET_TRAINING_SERVER_PROVIDER_LISTS_REQUEST,
    payload,
});

export const getTrainingServiceProviderMiddleware = (payload) => ({
    type: GET_TRAINING_SERVER_PROVIDER_LISTS_MIDDLEWARE_SUCCESS,
    payload,
});

export const getTrainingServiceProviderFail = (payload) => ({
    type: GET_TRAINING_SERVER_PROVIDER_LISTS_FAIL,
    payload,
});

export const getContentData = (payload) => ({
    type: GET_ALL_CONTENT_DATA,
    payload,
});

export const setContentData = (payload) => ({
    type: SET_ALL_CONTENT_DATA,
    payload,
})

export const setContentDataLoading = () => ({
    type: SET_ALL_CONTENT_DATA_LOADING,
    payload: false,
})
export const getTrainingUser = (payload) => ({
    type: GET_TRAINING_USER_LISTS_PAYLOAD,
    payload,
});
export const getTrainingUserBegin = (payload) => ({
    type: GET_TRAINING_USER_LISTS_REQUEST,
    payload,
});

export const getTrainingUserMiddleware = (payload) => ({
    type: GET_TRAINING_USER_LISTS_MIDDLEWARE_SUCCESS,
    payload,
});

export const getTrainingUserFail = (payload) => ({
    type: GET_TRAINING_USER_LISTS_FAIL,
    payload,
});

export const getTrainingMedia = (payload) => ({
    type: GET_ALL_CONTENT_DATA_PAYLOAD,
    payload,
});
export const getTrainingMediaBegin = (payload) => ({
    type: GET_ALL_CONTENT_DATA_REQUEST,
    payload,
});

export const getTrainingMediaMiddleware = (payload) => ({
    type: GET_ALL_CONTENT_DATA_MIDDLEWARE_SUCCESS,
    payload,
});

export const getTrainingMediaFail = (payload) => ({
    type: GET_ALL_CONTENT_DATA_FAIL,
    payload,
});
export const removeContent = () => ({
    type: REMOVE_CONTENT_DATA,
});

export const updateTrainingStatus = (payload) => ({
    type: UPDATE_TRAINING_STATUS,
    payload,
});

export const updateTrainingStatusSuccess = (payload) => ({ 
    type: TRAINING_STATUS_UPDATE_SUCCESS,
    payload,
});
