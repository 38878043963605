import homeMiddleswares from "./home/middleware";
import adminMiddlewares from "./admin/middleware";
import customerMiddlewares from "./customer/middleware";
import categoryMiddlewares from "./category/middleware";
import serviceProviderMiddlewares from "./serviceProvider/middleware";
import jobsMiddlewares from "./jobs/middleware";
import userMiddlewares from "./user/middleware";
import reviewMiddlewares from "./reviews/middleware";
import trainingMiddlewares from "./training/middleware";
import deviceMiddlewares from "./device/middleware";

export default [
    ...homeMiddleswares,
    ...adminMiddlewares,
    ...customerMiddlewares,
    ...categoryMiddlewares,
    ...serviceProviderMiddlewares,
    ...jobsMiddlewares,
    ...userMiddlewares,
    ...reviewMiddlewares,
    ...trainingMiddlewares,
    ...deviceMiddlewares,
];
