import axios from "axios";
import API_ROUTES, { infoBean } from "app/constants/routes/api";
import { getItem, setNonLoginSession } from "app/utils/session";
import get from "lodash/get";
export const generateNonLoginAuth = () => {
    return axios.post(API_ROUTES.LANDING, {
        androidUniqueId: `${Date.now()}`,
        infoBean,
    });
};

export const generateLoginAuth = (credentials) => {
    const nonLoginAuthToken = getItem("nonLoginAuthToken");
    const { username, password } = credentials;

    return new Promise((resolve, reject) => {
        if (nonLoginAuthToken) {
            axios
                .post(API_ROUTES.LOGIN, {
                    infoBean,
                    nonLoginAuthToken,
                    password,
                    username,
                })
                .then((res) => {
                    const resData = get(res, "data.data", undefined);
                    if (resData) resolve(res);
                    else {
                        const errorCode = get(res, "data.error.errorCode");
                        if (errorCode === 32) {
                            generateNonLoginAuth().then((res) => {
                                setNonLoginSession(res);
                                const reviseNonLoginAuthToken = getItem(
                                    "nonLoginAuthToken"
                                );
                                axios
                                    .post(API_ROUTES.LOGIN, {
                                        infoBean,
                                        nonLoginAuthToken: reviseNonLoginAuthToken,
                                        password,
                                        username,
                                    })
                                    .then((r) => resolve(r))
                                    .catch((e) => reject(e));
                            });
                        } else reject();
                    }
                })
                .catch((firstErr) => console.log("firstErr", firstErr));
        } else {
            generateNonLoginAuth().then((res) => {
                setNonLoginSession(res);
                const reviseNonLoginAuthToken = getItem("nonLoginAuthToken");
                axios
                    .post(API_ROUTES.LOGIN, {
                        infoBean,
                        nonLoginAuthToken: reviseNonLoginAuthToken,
                        password,
                        username,
                    })
                    .then((r) => resolve(r))
                    .catch((e) => reject(e));
            });
        }
    });
};

export const logout = () => {
    const refreshToken = getItem("refesh_token");

    return axios.post(API_ROUTES.LOGOUT, {
        refreshToken,
    });
};

export const refreshAuth = () => {
    const refreshToken = getItem("refesh_token");

    return axios.post(API_ROUTES.REFRESH_TOKEN, {
        refreshToken,
        infoBean,
    });
};
