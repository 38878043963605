export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GETTING_ALL_JOBS = "GETTING_ALL_JOBS";
export const SET_ALL_JOBS = "SET_ALL_JOBS";
export const GET_ALL_JOBS_ERROR = "GET_ALL_JOBS_ERROR";

export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";

export const SET_NOTIFICATION_JOB = "SET_NOTIFICATION_JOB";

export const ADD_EVIDENCE_JOB = "ADD_EVIDENCE_JOB";
export const ADD_EVIDENCE_JOB1 = "ADD_EVIDENCE_JOB1";
export const ADD_EVIDENCE_JOB_ERROR = "ADD_EVIDENCE_JOB_ERROR";
export const ADD_EVIDENCE_JOB_SUCCESS = "ADD_EVIDENCE_JOB_SUCCESS";

export const GET_EVIDENCE_JOB = "GET_EVIDENCE_JOB";
export const GET_EVIDENCE_JOB_MIDDLEWARE = "GET_EVIDENCE_JOB_MIDDLEWARE";

export const LOADER = "LOADER";
export const LANGUAGE_CHANGES = "LANGUAGE_CHANGES"
