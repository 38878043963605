import {
    ADD_BRAND_BEGIN,
    ADD_BRAND_SUCCESS,
    ADD_BRAND_FAIL,
    ADD_MODEL_BEGIN,
    ADD_MODEL_FAIL,
    ADD_PRODUCT_SERIES_BEGIN,
    ADD_PRODUCT_SERIES_FAIL,
    GET_BRAND_BEGIN,
    GET_BRAND_FAIL,
    GET_MODEL_BEGIN,
    GET_MODEL_FAIL,
    GET_PRODUCT_SERIES_BEGIN,
    GET_PRODUCT_SERIES_FAIL,
    ADD_BRAND_MIDDLEWARE,
    RESET_STATE,
    GET_BRAND_PAYLOAD,
    GET_BRAND_MIDDLEWARE,
    ADD_PRODUCT_SERIES_PAYLOAD,
    ADD_PRODUCT_SERIES_MIDDLEWARE_SUCCESS,
    GET_PRODUCT_SERIES_MIDDLEWARE_SUCCESS,
    GET_PRODUCT_SERIES_PAYLOAD,
    REMOVE_DATA,
    GET_MODEL_MIDDLEWARE_SUCCESS,
    GET_MODEL_PAYLOAD,
    ADD_MODEL_PAYLOAD,
    ADD_MODEL_MIDDLEWARE_SUCCESS,
    EDIT_BRAND,
    EDIT_BRAND_SUCCESS,
    EDIT_PRODUCT_SERIES_PAYLOAD,
    EDIT_PRODUCT_SERIES_SUCCESS,
    EDIT_MODEL_PAYLOAD,
    EDIT_MODEL_SUCCESS
} from "./types";

export const resetState = () => ({
    type: RESET_STATE,
});
export const removeDataFromRedux = (payload) => ({
    type: REMOVE_DATA,
    payload
});
export const addBrandSuccess = (payload) => ({
    type: ADD_BRAND_SUCCESS,
    payload,
});

export const editBrandAction = (payload) => ({
    type: EDIT_BRAND,
    payload,
});

export const editBrandSuccessAction = (payload) => ({
    type: EDIT_BRAND_SUCCESS,
    payload,
});

export const editProductSeries = (payload) => ({
    type: EDIT_PRODUCT_SERIES_SUCCESS,
    payload,
});

export const addBrandBegin = (payload) => ({
    type: ADD_BRAND_BEGIN,
    payload,
});
export const addBrandFail = (payload) => ({
    type: ADD_BRAND_FAIL,
    payload,
});
export const addBrandMiddleware = (payload) => ({
    type: ADD_BRAND_MIDDLEWARE,
    payload,
});

export const getBrand = (payload) => ({
    type: GET_BRAND_PAYLOAD,
    payload,
});
export const getBrandBegin = (payload) => ({
    type: GET_BRAND_BEGIN,
    payload,
});
export const getBrandFail = (payload) => ({
    type: GET_BRAND_FAIL,
    payload,
});
export const getBrandMiddleware = (payload) => {
   // console.log('payload getBrandMiddleware', payload)
    return {
        type: GET_BRAND_MIDDLEWARE,
        payload,
    };
}

export const addProductSeries = (payload) => ({
    type: ADD_PRODUCT_SERIES_PAYLOAD,
    payload,
});

export const editProductSeriesAction = (payload) => ({
    type: EDIT_PRODUCT_SERIES_PAYLOAD,
    payload,
});

export const addProductSeriesBegin = (payload) => ({
    type: ADD_PRODUCT_SERIES_BEGIN,
    payload,
});

export const addProductSeriesFail = (payload) => ({
    type: ADD_PRODUCT_SERIES_FAIL,
    payload,
});

export const addProductSeriesMiddleware = (payload) => ({
    type: ADD_PRODUCT_SERIES_MIDDLEWARE_SUCCESS,
    payload,
});

export const getProductSeries = (payload) => ({
    type: GET_PRODUCT_SERIES_PAYLOAD,
    payload,
});
export const getProductSeriesBegin = (payload) => ({
    type: GET_PRODUCT_SERIES_BEGIN,
    payload,
});
export const getProductSeriesFail = (payload) => ({
    type: GET_PRODUCT_SERIES_FAIL,
    payload,
});
export const getProductSeriesMiddleware = (payload) => ({
    type: GET_PRODUCT_SERIES_MIDDLEWARE_SUCCESS,
    payload,
});

export const getModel = (payload) => ({
    type: GET_MODEL_PAYLOAD,
    payload,
});
export const getModelBegin = (payload) => ({
    type: GET_MODEL_BEGIN,
    payload,
});
export const getModelFail = (payload) => ({
    type: GET_MODEL_FAIL,
    payload,
});
export const getModelMiddleware = (payload) => ({
    type: GET_MODEL_MIDDLEWARE_SUCCESS,
    payload,
});

export const addModel = (payload) => ({
    type: ADD_MODEL_PAYLOAD,
    payload,
});

export const editModel = (payload) => ({
    type: EDIT_MODEL_PAYLOAD,
    payload,
});

export const editModelSuccess = (payload) => ({
    type: EDIT_MODEL_SUCCESS,
    payload,
});

export const addModelBegin = (payload) => ({
    type: ADD_MODEL_BEGIN,
    payload,
});
export const addModelFail = (payload) => ({
    type: ADD_MODEL_FAIL,
    payload,
});
export const addModelMiddleware = (payload) => ({
    type: ADD_MODEL_MIDDLEWARE_SUCCESS,
    payload,
});


