import {
    SET_REVIEWS,
    GET_REVIEWS,
    SET_CATEGORY_REVIEWS,
    GET_CATEGORY_REVIEWS
} from "./types";

const initialState = {
    Reviews: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REVIEWS:
            return {
                ...state,
                Reviews: action.payload
            };

        default:
            return { ...state };
    }
};
