import {
    ADD_NEW_SERVICE_PROVIDER,
    ADDING_NEW_SERVICE_PROVIDER,
    ADD_NEW_SERVICE_PROVIDER_ERROR,
    NEW_SERVICE_PROVIDER_ADDED,
    GET_ALL_SERVICE_PROVIDERS,
    GETTING_ALL_SERVICE_PROVIDERS,
    SET_ALL_SERVICE_PROVIDERS,
    GET_ALL_SERVICE_PROVIDERS_ERROR,
    EDIT_SERVICE_PROVIDER_MIDDLEWARE
} from "./types";

export const addNewServiceProvider = (payload) => ({
    type: ADD_NEW_SERVICE_PROVIDER,
    payload,
});

export const editServiceProviderDataAction = (payload) => ({
    type: EDIT_SERVICE_PROVIDER_MIDDLEWARE,
    payload,
});

export const addingNewServiceProvider = () => ({
    type: ADDING_NEW_SERVICE_PROVIDER,
});

export const newServiceProviderAdded = (payload) => ({
    type: NEW_SERVICE_PROVIDER_ADDED,
    payload,
});
export const addNewServiceProviderError = (err) => ({
    type: ADD_NEW_SERVICE_PROVIDER_ERROR,
    err,
});

export const getAllServiceProvider = (payload) => ({
    type: GET_ALL_SERVICE_PROVIDERS,
    payload,
});

export const gettingAllServiceProvider = () => ({
    type: GETTING_ALL_SERVICE_PROVIDERS,
});

export const setAllServiceProvider = (payload) => ({
    type: SET_ALL_SERVICE_PROVIDERS,
    payload,
});

export const getAllServiceProviderError = (err) => ({
    type: GET_ALL_SERVICE_PROVIDERS_ERROR,
    err,
});
