export const ADD_NEW_SERVICE_PROVIDER = "ADD_NEW_SERVICE_PROVIDER";
export const EDIT_SERVICE_PROVIDER_MIDDLEWARE = "EDIT_SERVICE_PROVIDER_MIDDLEWARE";
export const ADDING_NEW_SERVICE_PROVIDER = "ADDING_NEW_SERVICE_PROVIDER";
export const NEW_SERVICE_PROVIDER_ADDED = "NEW_SERVICE_PROVIDER_ADDED";
export const ADD_NEW_SERVICE_PROVIDER_ERROR = "ADD_NEW_SERVICE_PROVIDER_ERROR";

export const GET_ALL_SERVICE_PROVIDERS = "GET_ALL_SERVICE_PROVIDERS";
export const GETTING_ALL_SERVICE_PROVIDERS = "GETTING_ALL_SERVICE_PROVIDERS";
export const SET_ALL_SERVICE_PROVIDERS = "SET_ALL_SERVICE_PROVIDERS";
export const GET_ALL_SERVICE_PROVIDERS_ERROR =
    "GET_ALL_SERVICE_PROVIDERS_ERROR";
