import APP_ROUTES from "app/constants/routes/app";
import history from "app/history";
import { logout } from "app/requests";
import { resetSession } from "app/utils/session";
import axios from "axios";
import get from "lodash/get";
export const axiosInterceptor = (store) => {
    axios.interceptors.request.use((req) => {
        req.withCredentials = true;
        return req;
    });

    axios.interceptors.response.use(async (res) => {
        const errorCode = get(res, "data.error.errorCode");
        if (errorCode === 11) {
            resetSession();

            logout().then(() => history.push(APP_ROUTES.LOGIN));
        }
        return res;
    });
};
