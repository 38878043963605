import {
    ADDING_NEW_SERVICE_PROVIDER,
    ADD_NEW_SERVICE_PROVIDER_ERROR,
    NEW_SERVICE_PROVIDER_ADDED,
    GETTING_ALL_SERVICE_PROVIDERS,
    SET_ALL_SERVICE_PROVIDERS,
    GET_ALL_SERVICE_PROVIDERS_ERROR,
} from "./types";

import get from "lodash/get";
import moment from "moment";

const initialState = {
    newServiceProvider: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
        created: false,
    },
    allServiceProviders: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADDING_NEW_SERVICE_PROVIDER:
            return {
                ...state,
                newServiceProvider: {
                    ...state.newServiceProvider,
                    loading: true,
                    created: false,
                },
            };
        case NEW_SERVICE_PROVIDER_ADDED:
            return {
                ...state,
                newServiceProvider: {
                    ...state.newServiceProvider,
                    loading: false,
                    created: true,
                },
            };
        case ADD_NEW_SERVICE_PROVIDER_ERROR:
            return {
                ...state,
                newServiceProvider: {
                    ...state.newServiceProvider,
                    loading: false,
                },
            };
        case GETTING_ALL_SERVICE_PROVIDERS:
            return {
                ...state,
                allServiceProviders: {
                    ...state.allServiceProviders,
                    loading: true,
                },
            };
        case SET_ALL_SERVICE_PROVIDERS:
            const allServiceProviderList = get(action.payload, "list", []);
            const lastPage = get(action.payload, "lastPage", 1);
            const requestedPage = get(action.payload, "requestedPage", 1);
            const tableData = allServiceProviderList.map((serviceProvider) => ({
                ...serviceProvider,
                name: `${serviceProvider.firstName} `,
                creationDate: moment(serviceProvider.creationDate).format(
                    "DD/MM/YYYY"
                ),
            }));

            const allData =
                // requestedPage === 1
                //     ?
                tableData;
            // : [...state.allServiceProviders.data, ...tableData];

            return {
                ...state,
                allServiceProviders: {
                    ...state.allServiceProviders,
                    loading: false,
                    data: allData,
                    lastPage: lastPage,
                },
            };
        case GET_ALL_SERVICE_PROVIDERS_ERROR:
            return {
                ...state,
                allServiceProviders: {
                    ...state.allServiceProviders,
                    loading: false,
                },
            };
        default:
            return { ...state };
    }
};
