import { message } from "antd";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import {
    addingNewCustomer,
    addingNewCustomerJob,
    addNewCustomerError,
    addNewCustomerJobError,
    deleteDeviceFromCustomer,
    editCustomerAction,
    getAllCustomersError,
    gettingAllCustomers,
    newCustomerAdded,
    newCustomerJobAdded,
    setAllCustomers,
    setAllDevice

} from "./action";
import {
    ADD_NEW_CUSTOMER,
    EDIT_CUSTOMER_MIDDLEWARE,
    ADD_NEW_CUSTOMER_JOB,
    GET_ALL_CUSTOMERS,
    GET_DEVICE_DATA,
    REMOVE_DEVICE_FROM_CUSTOMER_MIDDLEWARE,
} from "./types";
import get from "lodash/get";
import { Service } from "app/config/service";

const { createLogic } = require("redux-logic");

const addNewCustomerLogic = createLogic({
    type: ADD_NEW_CUSTOMER,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewCustomer());
        const formData = new FormData();
        const {
            firstName,
            lastName,
            email,
            contact,
            address,
            country,
            city,
            state,
            username,
            password,
            allCategories
        } = action.payload.data_;


        const profilePic = action.payload.profilePic
        let devices = { 
            "allDevices":allCategories
        }
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("emailId", email);
        formData.append("phoneNumber", contact);
        formData.append("address", address);
        formData.append("country", country);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("username", username);
        formData.append("password", password);
        formData.append("forRoleId", 4);
        formData.append("profilePic", profilePic);
        formData.append("category", "");
        formData.append("device", JSON.stringify(devices));
       
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        // axios
        //     .post(API_ROUTES.ADDUSER, formData, {
        //         headers: { "Content-Type": "multipart/form-data" },
        //     })

        Service({
            method: "post",
            url: API_ROUTES.ADDUSER,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                dispatch(newCustomerAdded());
                message.success("Customer Created Successfully!");
                done();
            })
            .catch((err) => {
                dispatch(addNewCustomerError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const updateCustomerLogic = createLogic({
    type: EDIT_CUSTOMER_MIDDLEWARE,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewCustomer());
        const formData = new FormData();
        const { allCategories, profilePic, authToken, userId} = action.payload;

        let devices = {
            "allDevices": allCategories
        }
        formData.append("device", JSON.stringify(devices));
        // const token = getItem("token");
        // formData.append("authToken", token ? token : "");

        if(profilePic){
            formData.append("profilePic", profilePic);
            formData.append("authToken", authToken);
            formData.append("userId", userId);
        }
        if(profilePic && Object.keys(action.payload).length < 5){
            Service({
                method: "post",
                url: API_ROUTES.UPDATE_USER_CONTENT,
                data: formData,
            }).then((res) => {
                dispatch(newCustomerAdded());
                dispatch(editCustomerAction({isUpdate: true}));
                done();
            }).catch((err) => {
                dispatch(addNewCustomerError(err));
                done();
            });
        }
        if(!profilePic && Object.keys(action.payload).length > 2){
            delete action.payload.profilePic
            Service({
                method: "post",
                url: API_ROUTES.EDITUSER,
                data: action.payload,
            }).then((res) => {
                dispatch(newCustomerAdded());
                dispatch(editCustomerAction({isUpdate: true}));
                message.success("Customer Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewCustomerError(err));
                message.error("Something went wrong!");
                done();
            });
        }
        if(profilePic && Object.keys(action.payload).length > 4){
            Service({
                method: "post",
                url: API_ROUTES.UPDATE_USER_CONTENT,
                data: formData,
            }).then((res) => {
                done();
            }).catch((err) => {
                dispatch(addNewCustomerError(err));
                done();
            });

            delete action.payload.profilePic
            Service({
                method: "post",
                url: API_ROUTES.EDITUSER,
                data: action.payload,
            }).then((res) => {
                dispatch(newCustomerAdded());
                dispatch(editCustomerAction({isUpdate: true}));
                message.success("Customer Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewCustomerError(err));
                message.error("Something went wrong!");
                done();
            });
        }
    }
});
const getAllCustomersLogic = createLogic({
    type: GET_ALL_CUSTOMERS,
    latest: true,
    process({ action }, dispatch, done) {
       
        const authToken = getItem("token");

        const payload = { authToken: authToken ? authToken : "", forRoleId: 4 };
       
        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_USERS(action.payload),
            data: { ...payload },
        })
        .then((res) => {
            const allCustomerData = get(res, "data.data", {});
            dispatch(
                setAllCustomers({
                    ...allCustomerData,
                    requestedPage: action.payload,
                })
            );
            done();
        })
        .catch((err) => {
            dispatch(getAllCustomersError(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const getAllDeviceLogic = createLogic({
    type: GET_DEVICE_DATA,
    latest: true,
    process({ action }, dispatch, done) {
            const authToken = getItem("token");
            const payload = { authToken: authToken ? authToken : "", userId: action.dataId};
            Service({
                method: "post",
                url: API_ROUTES.GET_USER_DEVICES(action.payload),
                data: { ...payload },
            })
            .then((res) => {
                const allDeviceData = get(res, "data.data", {});
                dispatch(
                    setAllDevice({
                        ...allDeviceData,
                        requestedPage: action.payload,
                    })
                );
                done();
            })
            .catch((err) => {
                //dispatch(getAllCustomersError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const removeDeviceFromCustomer = createLogic({
    type: REMOVE_DEVICE_FROM_CUSTOMER_MIDDLEWARE,
    latest: true,
    process({ action }, dispatch, done) {
        const data = action.payload
        const authToken = getItem("token");

        const payload = {
            authToken: authToken ? authToken : "",
            id: data.id,
            type: data.type,
            userId: data.userId,
        };
        Service({
            method: "post",
            url: API_ROUTES.REMOVE_DEVICES_FROM_CUSTOMER_API,
            data: { ...payload },
        }).then((res) => {

            dispatch(deleteDeviceFromCustomer(payload));
            message.success("Device remove successfully!");
            done();
        }).catch((err) => {
            //dispatch(getAllCustomersError(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const addNewCustomerJobLogic = createLogic({
    type: ADD_NEW_CUSTOMER_JOB,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewCustomerJob());
        const token = getItem("token");
    
        Service({
            method: "post",
            url: API_ROUTES.ADD_JOB,
            data: {
                ...action.payload,
                authToken: token,
            },
        })
            .then(() => {
                dispatch(newCustomerJobAdded());
                message.success("Job created Successfully!");
                done();
            })
            .catch((err) => {
                dispatch(addNewCustomerJobError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});
export default [
    addNewCustomerLogic,
    updateCustomerLogic,
    getAllCustomersLogic,
    addNewCustomerJobLogic,
    getAllDeviceLogic,
    removeDeviceFromCustomer
];
