import { refreshAuth } from "app/requests";
import { setItem } from "app/utils/session";
import axios from "axios";
import get from "lodash/get";

export const Service = (requestAttr) => {
    return new Promise((resolve, reject) => {
        const { data } = requestAttr;
        const resultAttr = { ...requestAttr, withCredentials: true };
        axios({ ...resultAttr })
            .then((res) => {
                const errorCode = get(res, "data.error.errorCode");
                if (errorCode === 32) {
                    refreshAuth().then((res) => {
                        const refreshedTokens = get(res, "data.data", {});
                        const { authToken, refreshToken } = refreshedTokens;
                        setItem("token", authToken);
                        setItem("refesh_token", refreshToken);
                        data.authToken = authToken;

                        axios({ ...resultAttr, data })
                            .then((r) => resolve(r))
                            .catch((err) => {
                                reject(err);
                            });
                    });
                } else resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
