import {
    SET_REVIEWS,
    GET_REVIEWS,
    ADD_REVIEWS,
    GET_USER_REVIEWS,
    GET_CATEGORY_REVIEWS,
    SET_CATEGORY_REVIEWS,
} from "./types";

export const setReview = (payload) => ({
    type: SET_REVIEWS,
    payload,
});

export const getReview = (payload) => ({
    type: GET_REVIEWS,
    payload,
});

export const getUserReview = (payload) => ({
    type: GET_USER_REVIEWS,
    payload,
});

export const getCategoryReview = (payload) => ({
    type: GET_CATEGORY_REVIEWS,
    payload,
});

export const SetCategoryReview = (payload) => ({
    type: SET_CATEGORY_REVIEWS,
    payload,
});

export const addReview = (payload) => ({
    type: ADD_REVIEWS,
    payload,
});
