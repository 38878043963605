import {
    ADDING_NEW_CUSTOMER,
    ADD_NEW_CUSTOMER_ERROR,
    NEW_CUSTOMER_ADDED,
    GETTING_ALL_CUSTOMERS,
    SET_ALL_CUSTOMERS,
    SET_ALL_DEVICE,
    GET_ALL_CUSTOMERS_ERROR,
    ADDING_NEW_CUSTOMER_JOB,
    NEW_CUSTOMER_JOB_ADDED,
    ADD_NEW_CUSTOMER_JOB_ERROR,
    REMOVE_DEVICE_FROM_CUSTOMER,
    EDIT_CUSTOMER_DATA
} from "./types";
import get from "lodash/get";
import moment from "moment";

const initialState = {
    newCustomer: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
        created: false,
    },
    allCustomers: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1,
        isUpdate: false
    },
    newJob: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
        created: false,
    },
    allDevices: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1
    } 
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADDING_NEW_CUSTOMER:
            return {
                ...state,
                newCustomer: {
                    ...state.newCustomer,
                    loading: true,
                    created: false,
                },
            };
        case NEW_CUSTOMER_ADDED:
            return {
                ...state,
                newCustomer: {
                    ...state.newCustomer,
                    loading: false,
                    created: true,
                },
            };
        case ADD_NEW_CUSTOMER_ERROR:
            return {
                ...state,
                newCustomer: { ...state.newCustomer, loading: false },
            };
        case GETTING_ALL_CUSTOMERS:
            return {
                ...state,
                allCustomers: { ...state.allCustomers, loading: true },
            };
        case SET_ALL_CUSTOMERS:
            const allCustomerList = get(action.payload, "list", []);
            const lastPage = get(action.payload, "lastPage", 1);
            const requestedPage = get(action.payload, "requestedPage", 1);
            const tableData = allCustomerList.map((customer) => ({
                ...customer,
                name: `${customer.firstName} `,
                creationDate: moment(customer.creationDate).format(
                    "DD/MM/YYYY"
                ),
            }));

            const allData =
                // requestedPage === 1
                //     ?
                tableData;
            // : [...state.allCustomers.data, ...tableData];

            return {
                ...state,
                allCustomers: {
                    ...state.allCustomers,
                    loading: false,
                    data: allData,
                    lastPage: lastPage,
                },
            };

        case SET_ALL_DEVICE:
            const allDeviceData =  get(action.payload,"list",[]);
            const lastPages = get(action.payload, "lastPage", 1);
            const deviceTableData = allDeviceData.map((device)=>({
                ...device,
                activityData: moment(device.modificationDate).format(
                    "DD/MM/YYYY"
                ),
            }));
            const allDevicesData = deviceTableData;

        //    console.log('allDeviceData===>>', allDevicesData)
            return{
                ...state,
                allDevices:{
                    ...state.allDevices,
                    loading:false,
                    data:allDevicesData,
                    lastPage:lastPages
                },
            };
        case REMOVE_DEVICE_FROM_CUSTOMER:
            const cloneAllDev = [...state.allDevices.data]
            const ind = cloneAllDev.findIndex(p => p.id === action?.payload?.id)
            cloneAllDev.splice(ind, 1)
            return{
                ...state,
                allDevices:{
                    ...state.allDevices,
                    loading:false,
                    data:cloneAllDev,
                },
            };
        case GET_ALL_CUSTOMERS_ERROR:
            return {
                ...state,
                allCustomers: { ...state.allCustomers, loading: false },
            };
        case ADDING_NEW_CUSTOMER_JOB:
            return {
                ...state,
                newJob: { ...state.newJob, loading: true, created: false },
            };
        case NEW_CUSTOMER_JOB_ADDED:
            return {
                ...state,
                newJob: { ...state.newJob, loading: false, created: true },
            };
        case ADD_NEW_CUSTOMER_JOB_ERROR:
            return {
                ...state,
                newJob: { ...state.newJob, loading: false },
            };
        case EDIT_CUSTOMER_DATA:
          return {
            ...state,
            allCustomers: {
                ...state.allCustomers,
                loading: false,
                isUpdate: action.payload.isUpdate,
            },
        };
        default:
            return { ...state };
    }
};
