import React, { Suspense, useEffect } from "react";
import ReactGA from 'react-ga';
import "./App.scss";
import { Router, Switch, Route } from "react-router";
import history from "app/history";
import APP_ROUTES from "app/constants/routes/app";
import PrivateRoute from "app/common/HOC/PrivateRoute";
// import messaging from "../public/firebase-messaging-sw";
import firebase from "./firebase";
const LandingPage = React.lazy(() => import("app/views/pages/Landing"));
const LoginPage = React.lazy(() => import("app/views/pages/User/Login"));
const HomePage = React.lazy(() => import("app/views/pages/Home"));
const PasswordPage = React.lazy(() => import("app/views/pages/User/Password"));

ReactGA.initialize('UA-183758480-1', {
    debug: true,
    titleCase: false,
    redactEmail: false
});

const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        const messaging = firebase.messaging();
        messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

const onMessageListener = () =>
    new Promise((resolve) => {
        // messaging.onMessage((payload) => {
        //     resolve(payload);
        // });
    });
function App() {
    useEffect(() => {
        requestFirebaseNotificationPermission()
            .then((firebaseToken) => {
                // eslint-disable-next-line no-console
                // console.log(firebaseToken);
            })
            .catch((err) => {
                return err;
            });
    }, []);

    // let lang = localStorage.getItem("lang")
    // useEffect(()=>{
    //     if(lang === null){
    //         localStorage.setItem("lang","en")
    //     }
    // })
    
    return (
        <>
            <Router history={history}>
                <Suspense fallback={<>loading...</>}>
                    <Switch>
                        <Route
                            exact
                            path={APP_ROUTES.LANDING}
                            component={LandingPage}
                        />
                        <Route
                            exact
                            path={APP_ROUTES.LOGIN}
                            component={LoginPage}
                        />
                        <Route
                            exact
                            path={APP_ROUTES.PASSWORD}
                            component={PasswordPage}
                        />
                        <PrivateRoute
                            exact
                            path={APP_ROUTES.HOME}
                            component={HomePage}
                        />

                        <Route
                            path="*"
                            component={() => <>No Routes Found</>}
                        />
                    </Switch>
                </Suspense>
            </Router>
        </>
    );
}

export default App;
