import { cloneElement } from "react";
import {
    GET_ALL_JOBS,
    GETTING_ALL_JOBS,
    SET_ALL_JOBS,
    GET_ALL_JOBS_ERROR,
    UPDATE_JOB,
    UPDATE_JOB_SUCCESS,
    SET_NOTIFICATION_JOB,
    ADD_EVIDENCE_JOB,
    ADD_EVIDENCE_JOB1,
    GET_EVIDENCE_JOB,
    GET_EVIDENCE_JOB_MIDDLEWARE,
    LOADER,
    ADD_EVIDENCE_JOB_ERROR,
    ADD_EVIDENCE_JOB_SUCCESS,
    LANGUAGE_CHANGES
} from "./types";

export const getAllJobs = (payload) => ({
    type: GET_ALL_JOBS,
    payload,
});
export const gettingAllJobs = () => ({
    type: GETTING_ALL_JOBS,
});
export const setAllJobs = (payload) => ({
    type: SET_ALL_JOBS,
    payload,
});
export const getAllJobsError = (err) => ({
    type: GET_ALL_JOBS_ERROR,
    err,
});

export const updateJob = (payload) => ({
    type: UPDATE_JOB,
    payload,
});

export const updateJobSuccess = (payload) => ({
    type: UPDATE_JOB_SUCCESS,
    payload,
});

export const setNotificationJob = (payload) => ({
    type: SET_NOTIFICATION_JOB,
    payload,
});

export const AddJobEvidence = (payload) => ({
    type: ADD_EVIDENCE_JOB,
    payload
});

export const AddJobEvidence1 = (payload) => ({
    type: ADD_EVIDENCE_JOB1,
    payload: payload
});
export const AddJobEvidenceError = () => ({
    type: ADD_EVIDENCE_JOB_ERROR,
});
export const AddJobEvidenceSuccess = (payload) => ({
    type: ADD_EVIDENCE_JOB_SUCCESS,
    payload
});

export const getJobEvidence = (payload) => ({
    type: GET_EVIDENCE_JOB,
    payload,
});

export const getJobEvidenceMiddleware = (payload) => ({
    type: GET_EVIDENCE_JOB_MIDDLEWARE,
    payload: payload
});

export const apiLoader = (payload) => ({
    type: LOADER,
    payload: payload
});

export const languageChange = (payload) => ({
    type: LANGUAGE_CHANGES,
    payload: payload
})
