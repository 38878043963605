import {
    ADD_NEW_CATEGORY,
    ADDING_NEW_CATEGORY,
    ADD_NEW_CATEGORY_ERROR,
    NEW_CATEGORY_ADDED,
    GET_ALL_CATEGORIES,
    GETTING_ALL_CATEGORIES,
    SET_ALL_CATEGORIES,
    GET_ALL_CATEGORIES_ERROR,
    GET_PAGE_CATEGORIES,
    GETTING_PAGE_CATEGORIES,
    GET_PAGE_CATEGORIES_ERROR,
    SET_PAGE_CATEGORIES,
    SET_ALL_SUBCATEGORIES,
    SET_NOTIFICATION_CATEGORY,
    GET_CATEGORY_REVIEW,
    GET_CATEGORY_REVIEW1,
    REMOVE_CATEGORY_REVIEW
} from "./types";

export const addNewCategory = (payload) => ({
    type: ADD_NEW_CATEGORY,
    payload,
});
export const addingNewCategory = () => ({
    type: ADDING_NEW_CATEGORY,
});
export const newCategoryAdded = (payload) => ({
    type: NEW_CATEGORY_ADDED,
    payload,
});
export const addNewCategoryError = (err) => ({
    type: ADD_NEW_CATEGORY_ERROR,
    err,
});

export const getAllCategories = (payload) => ({
    type: GET_ALL_CATEGORIES,
    payload,
});
export const gettingAllCategories = () => ({
    type: GETTING_ALL_CATEGORIES,
});
export const setAllCategories = (payload) => ({
    type: SET_ALL_CATEGORIES,
    payload,
});

export const setAllSubCategories = (payload) => ({
    type: SET_ALL_SUBCATEGORIES,
    payload,
});

export const getAllCategoriesError = (err) => ({
    type: GET_ALL_CATEGORIES_ERROR,
    err,
});

export const getPageCategories = (payload) => ({
    type: GET_PAGE_CATEGORIES,
    payload,
});
export const gettingPageCategories = () => ({
    type: GETTING_PAGE_CATEGORIES,
});
export const setPageCategories = (payload) => ({
    type: SET_PAGE_CATEGORIES,
    payload,
});
export const getPageCategoriesError = (err) => ({
    type: GET_PAGE_CATEGORIES_ERROR,
    err,
});

export const setNotificationCategory = (payload) => ({
    type: SET_NOTIFICATION_CATEGORY,
    payload,
});
export const getCategoryRating = (payload) => ({
    type: GET_CATEGORY_REVIEW,
    payload,
});
export const getCategoryRating1 = (payload) => ({
    type: GET_CATEGORY_REVIEW1,
    payload: payload?.data?.data
})

export const removeCategoryReviewDataFromRedux = () => ({
    type: REMOVE_CATEGORY_REVIEW,
})
