const BASE_URL =
    // `http://ec2-54-249-168-143.ap-northeast-1.compute.amazonaws.com/api/rs`;
    `${process.env.REACT_APP_API_ENDPOINT}/api/rs`;
const API_ROUTES = {
    LANDING: `${BASE_URL}/web/v1/service/generate/nonLogin`,
    LOGIN: `${BASE_URL}/web/v1/service/login/password`,
    LOGOUT: `${BASE_URL}/web/v1/service/logout`,
    REFRESH_TOKEN: `${BASE_URL}/web/v1/service/refresh/authtoken`,
    ADDUSER: `${BASE_URL}/web/v1/service/add/newuser`,
    EDITUSER: `${BASE_URL}/web/v1/service/update/profile`,
    GET_PROFILE_DETAILS: `${BASE_URL}/web/v1/service/get/userprofile`,
    ADD_CATEGORY: `${BASE_URL}/web/v1/service/add/category`,
    GET_CATEGORIES: `${BASE_URL}/web/v1/service/get/cateogry`,
    GET_ALL_USERS: (pageNumber) =>
        `${BASE_URL}/web/v1/service/get/allusers/${pageNumber}`,
    GET_PAGINATED_CATEGORIES: (pageNumber) =>
        `${BASE_URL}/web/v1/service/get/allcategories/${pageNumber}`,
    GET_ALL_JOBS: (pageNumber) =>
        `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/jobs/${pageNumber}`,
    ADD_JOB: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/post/job`,
    GET_ALL_SP: (pageNumber) =>
        `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/sp/${pageNumber}`,
    UPDATE_JOB: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/update/job`,
    GET_ALL_USERS_BY_CATEGORY: (pageNumber) =>
        `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/allusers/category/${pageNumber}`,
    GET_NOTIFICATION_COUNT: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/unread/notification`,
    GET_ALL_NOTIFICATIONS: (pageNumber) =>
        `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/notification/${pageNumber}`,
    GET_A_JOB: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/a/job`,
    GET_A_CATEGORY: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/a/category`,
    UPDATE_NOTIFICATION: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/update/notification`,
    GET_DASHBOARD: `${BASE_URL}/web/v1/service/get/count`,
    ADD_REVIEWS: `${process.env.REACT_APP_API_ENDPOINT}/review/api/rs/web/v1/service/post/review`,
    GET_USER_REVIEWS: (pageNumber) => `${process.env.REACT_APP_API_ENDPOINT}/review/api/rs/web/v1/service/get/review/${pageNumber}`,
    GET_CATEGORY_REVIEWS1: (pageNumber) => `${process.env.REACT_APP_API_ENDPOINT}/review/api/rs/web/v1/service/get/category/review/${pageNumber}`,
    GET_CATEGORY_RATING: `${process.env.REACT_APP_API_ENDPOINT}/review/api/rs/web/v1/service/get/rating`,
    ADD_JOB_EVIDENCE: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/add/report`,
    ADD_TRAINING: `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/add/training`,
    GET_JOB_EVIDENCE: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/get/report`,
    ADD_CONTENT: (type) =>
        `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/add/${type}/topic`,
    ADD_BRAND: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/add/brand`,
    ADD_PRODUCT_SERIES: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/add/brand`,
    ADD_MODEL: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/add/brand`,
    GET_BRAND: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/get/all/brand/`,
    GET_MODEL: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/get/all/brand/`,
    GET_PRODUCT_SERIES: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/get/all/brand/`,
    GET_ALL_CHAPTERS: `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/get/a/training`,
    GET_ALL_CONTENT_DATA: `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/get/topic/content`,
    GET_ALL_TRAINING: (pageNumber) =>
        `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/get/training/${pageNumber}`,
    GET_ALL_USER_TRAINING: (pageNumber) =>
        `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/get/user/training/${pageNumber}`,
    GET_ALL_TRAINING_SP: `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/get/sp/1`,
    ASSIGN_TRAINING: `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/assign/training`,
    UPDATE_ASSIGN_TRAINING: `${process.env.REACT_APP_API_ENDPOINT}/training/api/rs/web/v1/service/update/assign/training`,
    GET_USER_DEVICES: (pageNo) => `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/get/user/device/${pageNo}`,
    ADD_ADMIN_JOB: `${process.env.REACT_APP_API_ENDPOINT}/job/api/rs/web/v1/service/post/job`,
    ADD_TOOL: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/add/tool`,
    GET_TOOL: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/get/all/tool/`,
    DELETE_DEVICE: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/remove/user/device/tool`,
    UPDATE_TOOL: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/update/tool`,
    UPDATE_DEVICES: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/update/brand`,
    UPDATE_USER_CONTENT: `${process.env.REACT_APP_API_ENDPOINT}/api/rs/web/v1/service/update/user/content`,
    REMOVE_DEVICES_FROM_CUSTOMER_API: `${process.env.REACT_APP_API_ENDPOINT}/device/api/rs/web/v1/service/remove/user/device/tool`,
};

export default API_ROUTES;

export const infoBean = {
    androidId: "string",
    androidVersion: "string",
    appName: "string",
    appSignature: "string",
    appVersion: "string",
    deviceModel: "string",
    fcmId: "string",
    manufacturer: "string",
    platform: "WEB",
    userAgent: "website",
};
