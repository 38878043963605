import { combineReducers } from "redux";
import home from "./home";
import admin from "./admin";
import customer from "./customer";
import category from "./category";
import serviceProvider from "./serviceProvider";
import jobs from "./jobs";
import user from "./user";
import reviews from "./reviews";
import training from "./training";
import device from "./device";

const rootReducer = combineReducers({
    home,
    admin,
    customer,
    category,
    serviceProvider,
    jobs,
    user,
    reviews,
    training,
    device,

});

export default rootReducer;
