import { message } from "antd";
import { Service } from "app/config/service";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";
import { GET_DASHBOARD_DETAILS, GET_NOTIFICATION_COUNT } from "./types";
import { setNotificationCount, setDashboardDetails } from "./action";
import get from "lodash/get";

const { createLogic } = require("redux-logic");

const getNotificationCountLogic = createLogic({
    type: GET_NOTIFICATION_COUNT,
    latest: true,
    process({ action, getState }, dispatch, done) {
        // axios;
        Service({
            method: "post",
            url: API_ROUTES.GET_NOTIFICATION_COUNT,
            data: { authToken: getItem("token") },
        })
            .then((res) => {
                const count = get(res, "data.data.unReadCount", 0);
                dispatch(setNotificationCount(count));

                done();
            })
            .catch((err) => {
                message.error("Error in fetching notifications");
                done();
            });
    },
});

const getDashboardLogic = createLogic({
    type: GET_DASHBOARD_DETAILS,
    latest: true,
    process({ action, getState }, dispatch, done) {
        // axios;
        Service({
            method: "post",
            url: API_ROUTES.GET_DASHBOARD,
            data: { authToken: getItem("token") },
        })
            .then((res) => {
                const countDetails = get(res, "data.data", {});
                dispatch(setDashboardDetails(countDetails));
                done();
            })
            .catch((err) => {
                message.error("Error in fetching notifications");
                done();
            });
    },
});

export default [getNotificationCountLogic, getDashboardLogic];
