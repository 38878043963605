export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const ADDING_NEW_CATEGORY = "ADDING_NEW_CATEGORY";
export const NEW_CATEGORY_ADDED = "NEW_CATEGORY_ADDED";
export const ADD_NEW_CATEGORY_ERROR = "ADD_NEW_CATEGORY_ERROR";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GETTING_ALL_CATEGORIES = "GETTING_ALL_CATEGORIES";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const SET_ALL_SUBCATEGORIES = "SET_ALL_SUBCATEGORIES";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const GET_PAGE_CATEGORIES = "GET_PAGE_CATEGORIES";
export const GETTING_PAGE_CATEGORIES = "GETTING_PAGE_CATEGORIES";
export const SET_PAGE_CATEGORIES = "SET_PAGE_CATEGORIES";
export const GET_PAGE_CATEGORIES_ERROR = "GET_PAGE_CATEGORIES_ERROR";

export const SET_NOTIFICATION_CATEGORY = "SET_NOTIFICATION_CATEGORY";

export const GET_CATEGORY_REVIEW = "GET_CATEGORY_REVIEW";
export const GET_CATEGORY_REVIEW1 = "GET_CATEGORY_REVIEW1";
export const REMOVE_CATEGORY_REVIEW = "REMOVE_CATEGORY_REVIEW"