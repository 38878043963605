import APP_ROUTES from "app/constants/routes/app";
import { getItem } from "app/utils/session";
import React from "react";
import { Router, Switch, Route, Redirect } from "react-router";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = getItem("token");
    if (!isAuthenticated) console.log("REDIRECT");
    if (!isAuthenticated) return <Redirect to={APP_ROUTES.LOGIN} />;
    return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
