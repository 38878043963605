import {
    ADD_NEW_CUSTOMER,
    ADDING_NEW_CUSTOMER,
    ADD_NEW_CUSTOMER_ERROR,
    NEW_CUSTOMER_ADDED,
    GET_ALL_CUSTOMERS,
    GETTING_ALL_CUSTOMERS,
    SET_ALL_CUSTOMERS,
    GET_ALL_CUSTOMERS_ERROR,
    ADD_NEW_CUSTOMER_JOB,
    ADDING_NEW_CUSTOMER_JOB,
    NEW_CUSTOMER_JOB_ADDED,
    GET_DEVICE_DATA,
    SET_ALL_DEVICE,
    ADD_NEW_CUSTOMER_JOB_ERROR,
    REMOVE_DEVICE_FROM_CUSTOMER,
    REMOVE_DEVICE_FROM_CUSTOMER_MIDDLEWARE,
    EDIT_CUSTOMER_MIDDLEWARE,
    EDIT_CUSTOMER_DATA
} from "./types";

export const addNewCustomer = (payload) => ({
    type: ADD_NEW_CUSTOMER,
    payload,
});
export const editCustomerDataAction = (payload) => ({
    type: EDIT_CUSTOMER_MIDDLEWARE,
    payload,
});

export const editCustomerAction = (payload) => ({
    type: EDIT_CUSTOMER_DATA,
    payload,
});

export const addingNewCustomer = () => ({
    type: ADDING_NEW_CUSTOMER,
});
export const newCustomerAdded = (payload) => ({
    type: NEW_CUSTOMER_ADDED,
    payload,
});
export const addNewCustomerError = (err) => ({
    type: ADD_NEW_CUSTOMER_ERROR,
    err,
});

export const getAllCustomers = (payload) => ({
    type: GET_ALL_CUSTOMERS,
    payload,
});

export const getAllDeviceData = (payload,dataId) => ({
    type: GET_DEVICE_DATA,
    payload,
    dataId
})

export const removeDeviceFromCustomer = (payload) => ({
    type: REMOVE_DEVICE_FROM_CUSTOMER_MIDDLEWARE,
    payload
})

export const deleteDeviceFromCustomer = (payload) => ({
    type: REMOVE_DEVICE_FROM_CUSTOMER,
    payload
})

export const gettingAllCustomers = () => ({
    type: GETTING_ALL_CUSTOMERS,
});
export const setAllCustomers = (payload) => ({
    type: SET_ALL_CUSTOMERS,
    payload,
});

export const setAllDevice = (payload) =>({
    type: SET_ALL_DEVICE,
    payload
})
export const getAllCustomersError = (err) => ({
    type: GET_ALL_CUSTOMERS_ERROR,
    err,
});

export const addNewCustomerJob = (payload) => ({
    type: ADD_NEW_CUSTOMER_JOB,
    payload,
});
export const addingNewCustomerJob = () => ({
    type: ADDING_NEW_CUSTOMER_JOB,
});
export const newCustomerJobAdded = (payload) => ({
    type: NEW_CUSTOMER_JOB_ADDED,
    payload,
});
export const addNewCustomerJobError = (err) => ({
    type: ADD_NEW_CUSTOMER_JOB_ERROR,
    err,
});
