import { message } from "antd";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import {
    addBrandMiddleware,
    addBrandBegin,
    addBrandFail,
    getBrandMiddleware,
    getBrandBegin,
    getBrandFail,
    addProductSeriesBegin,
    addProductSeriesMiddleware,
    addProductSeriesFail,
    getProductSeriesBegin,
    getProductSeriesMiddleware,
    getProductSeriesFail,
    addModelBegin,
    addModelMiddleware,
    addModelFail,
    getModelBegin,
    getModelMiddleware,
    getModelFail,
    editBrandSuccessAction,
    editProductSeries,
    editModelSuccess
} from "./action";
import {
    ADD_BRAND_SUCCESS,
    GET_BRAND_PAYLOAD,
    ADD_PRODUCT_SERIES_PAYLOAD,
    GET_PRODUCT_SERIES_PAYLOAD,
    ADD_MODEL_PAYLOAD,
    GET_MODEL_PAYLOAD,
    EDIT_BRAND,
    EDIT_PRODUCT_SERIES_PAYLOAD,
    EDIT_MODEL_PAYLOAD,
} from "./types";
import { Service } from "app/config/service";

const { createLogic } = require("redux-logic");

const addBrandLogic = createLogic({
    type: ADD_BRAND_SUCCESS,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addBrandBegin());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            isTool
        } = action.payload;

        formData.append("name", name);
        formData.append("shortDescription", shortDescription);
        formData.append("longDescription", longDescription);
        formData.append("image", profilePic);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        const apiUrl = isTool ? API_ROUTES.ADD_TOOL : API_ROUTES.ADD_BRAND
        Service({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                dispatch(addBrandMiddleware(res));
                message.success("Brand Created Successfully!");
                done();
            })
            .catch((err) => {
                dispatch(addBrandFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});


const editBrandLogic = createLogic({
    type: EDIT_BRAND,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addBrandBegin());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            id,
            isTool
        } = action.payload;

        if(name){
            formData.append("name", name);
        }
        if(shortDescription){
            formData.append("shortDescription", shortDescription);
        }
        if(longDescription){
            formData.append("longDescription", longDescription);
        }
        if(profilePic){
            formData.append("image", profilePic);
        }
        formData.append("id", id);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        const apiUrl = isTool ? API_ROUTES.UPDATE_TOOL : API_ROUTES.UPDATE_DEVICES
        Service({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            dispatch(editBrandSuccessAction(action.payload));
            message.success("Brand Update Successfully!");
            done();
        }).catch((err) => {
            dispatch(addBrandFail(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const addProductSeriesLogic = createLogic({
    type: ADD_PRODUCT_SERIES_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addProductSeriesBegin());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            brandId,
            isTool
        } = action.payload;

        formData.append("name", name);
        formData.append("shortDescription", shortDescription);
        formData.append("longDescription", longDescription);
        formData.append("image", profilePic);
        formData.append("brandId", brandId);

        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        let apiUrl = ""
        if(isTool){
            apiUrl = API_ROUTES.ADD_TOOL
        }else{
            apiUrl = API_ROUTES.ADD_PRODUCT_SERIES
        }
        Service({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                if (res.data.error === null) {
                    message.success("Product Series Created Successfully!");
                    dispatch(addProductSeriesMiddleware(res));
                } else {
                    message.error("Something went wrong!");
                    dispatch(addProductSeriesFail(res.data.error));
                }
                done();
            })
            .catch((err) => {
                dispatch(addProductSeriesFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const editProductSeriesLogic = createLogic({
    type: EDIT_PRODUCT_SERIES_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addBrandBegin());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            isTool,
            id
        } = action.payload;

        if(name){
            formData.append("name", name);
        }
        if(shortDescription){
            formData.append("shortDescription", shortDescription);
        }
        if(longDescription){
            formData.append("longDescription", longDescription);
        }
        if(profilePic){
            formData.append("image", profilePic);
        }
        formData.append("id", id);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        const apiUrl = isTool ? API_ROUTES.UPDATE_TOOL : API_ROUTES.UPDATE_DEVICES
        Service({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            dispatch(editProductSeries(action.payload));
            message.success("Product Series Update Successfully!");
            done();
        }).catch((err) => {
            dispatch(addBrandFail(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const addModelLogic = createLogic({
    type: ADD_MODEL_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addModelBegin());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            brandId,
            productSeriesId,
            isTool
        } = action.payload;

        formData.append("name", name);
        formData.append("shortDescription", shortDescription);
        formData.append("longDescription", longDescription);
        formData.append("image", profilePic);
        formData.append("brandId", brandId);
        formData.append("productSeriesId", productSeriesId);

        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        let apiUrl = ""
        if(isTool){
            apiUrl = API_ROUTES.ADD_TOOL
        }else{
            apiUrl = API_ROUTES.ADD_MODEL
        }
        Service({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                if (res.data.error === null) {
                    message.success("Model Created Successfully!");
                    dispatch(addModelMiddleware(res));
                } else {
                    message.error("Something went wrong!");
                    dispatch(addModelFail(res.data.error));
                }
                done();
            })
            .catch((err) => {
                dispatch(addModelFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const editModelLogic = createLogic({
    type: EDIT_MODEL_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addModelBegin());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            isTool,
            id
        } = action.payload;

        if(name){
            formData.append("name", name);
        }
        if(shortDescription){
            formData.append("shortDescription", shortDescription);
        }
        if(longDescription){
            formData.append("longDescription", longDescription);
        }
        if(profilePic){
            formData.append("image", profilePic);
        }
        formData.append("id", id);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        let apiUrl = ""
        if(isTool){
            apiUrl = API_ROUTES.UPDATE_TOOL
        }else{
            apiUrl = API_ROUTES.UPDATE_DEVICES
        }
        Service({
            method: "post",
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            if (res.data.error === null) {
                message.success("Model Update Successfully!");
                dispatch(editModelSuccess(action.payload));
            } else {
                message.error("Something went wrong!");
                dispatch(addModelFail(res.data.error));
            }
            done();
        }).catch((err) => {
            dispatch(addModelFail(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const getBrandLogic = createLogic({
    type: GET_BRAND_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getBrandBegin());
        const formData = new FormData();
        const {
            type,
            pageNo,
            isTool
        } = action.payload;
        formData.append("type", type);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        const payload = {
            "authToken": `${token ? token : ""}`,
            "type": `${type}`
        }
        let apiUrl = ""
        if(isTool){
            apiUrl = pageNo ? (`${API_ROUTES.GET_TOOL}${pageNo}`) : (`${API_ROUTES.GET_TOOL}1`)
        }else{
            apiUrl = pageNo ? (`${API_ROUTES.GET_BRAND}${pageNo}`) : (`${API_ROUTES.GET_BRAND}1`)
        }
        Service({
            method: "post",
            url: apiUrl,
            data: payload,
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (res.data.error === null) {
                    dispatch(getBrandMiddleware(res.data.data));
                } else {
                    message.error("Something went wrong!");
                    dispatch(getBrandFail(res.data.error));
                }

                done();
            })
            .catch((err) => {
                dispatch(getBrandFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const getProductSeriesLogic = createLogic({
    type: GET_PRODUCT_SERIES_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getProductSeriesBegin());
        const formData = new FormData();
        const {
            type,
            parentId,
            pageNo,
            isTool
        } = action.payload;
        formData.append("type", type);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        const payload = {
            "authToken": `${token ? token : ""}`,
            "type": `${type}`,
            "parentId": `${(parentId * 1)}`
        }
        let apiUrl = ""
        if(isTool){
            apiUrl = pageNo ? (`${API_ROUTES.GET_TOOL}${pageNo}`) : (`${API_ROUTES.GET_TOOL}1`)
        }else{
            apiUrl = pageNo ? (`${API_ROUTES.GET_PRODUCT_SERIES}${pageNo}`) : (`${API_ROUTES.GET_PRODUCT_SERIES}1`)
        }
        Service({
            method: "post",
            url: apiUrl,
            data: payload,
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (res.data.error === null) {
                    dispatch(getProductSeriesMiddleware(res.data.data));
                } else {
                    message.error("Something went wrong!");
                    dispatch(getProductSeriesFail(res.data.error));
                }

                done();
            })
            .catch((err) => {
                dispatch(getProductSeriesFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});
const getModelLogic = createLogic({
    type: GET_MODEL_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getModelBegin());
        const formData = new FormData();
        const {
            type,
            parentId,
            pageNo,
            isTool
        } = action.payload;
        formData.append("type", type);
        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        const payload = {
            "authToken": `${token ? token : ""}`,
            "type": `${type}`,
            "parentId": `${(parentId * 1)}`
        }
        let apiUrl = ""
        if(isTool){
            apiUrl = pageNo ? (`${API_ROUTES.GET_TOOL}${pageNo}`) : (`${API_ROUTES.GET_TOOL}1`)
        }else{
            apiUrl = pageNo ? (`${API_ROUTES.GET_MODEL}${pageNo}`) : (`${API_ROUTES.GET_MODEL}1`)
        }
        Service({
            method: "post",
            url: apiUrl,
            data: payload,
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (res.data.error === null) {
                    dispatch(getModelMiddleware(res.data.data));
                } else {
                    message.error("Something went wrong!");
                    dispatch(getModelFail(res.data.error));
                }

                done();
            })
            .catch((err) => {
                dispatch(getModelFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});


export default [
    addBrandLogic,
    editBrandLogic,
    getBrandLogic,
    addProductSeriesLogic,
    getProductSeriesLogic,
    addModelLogic,
    getModelLogic,
    editProductSeriesLogic,
    editModelLogic
];
