import { message } from "antd";
import { Service } from "app/config/service";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import get from "lodash/get";
import {
    SetCategoryReview,
    setReview
} from "./action";
import {
    ADD_REVIEWS,
    GET_REVIEWS,
    GET_USER_REVIEWS,
    GET_CATEGORY_REVIEWS,
} from "./types";

const { createLogic } = require("redux-logic");

const getCategoryReviewsLogic = createLogic({
    type: GET_CATEGORY_REVIEWS,
    latest: true,
    process({ action }, dispatch, done) {
        const { page, forUserId, category, forRoleId } = action.payload;
        const authToken = getItem("token");
        // const payload = { authToken: authToken ? authToken : "", forUserId, category, forRoleId };
        const payload = { authToken: authToken ? authToken : "", category, forRoleId };

        Service({
            method: "post",
            // url: API_ROUTES.GET_REVIEWS(page),
            url: API_ROUTES.GET_CATEGORY_REVIEWS1(page),
            data: { ...payload },
        })
            .then((res) => {
                const allReviewData = get(res, "data.data", {});
                dispatch(
                    setReview({
                        ...allReviewData,
                        requestedPage: page,
                    })
                );
                done();
            })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});

const getUserReviewsLogic = createLogic({
    type: GET_USER_REVIEWS,
    latest: true,
    process({ action }, dispatch, done) {
        const { page, userId, category, forRoleId } = action.payload;
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", userId, category, forRoleId };
        // const payload = { authToken: authToken ? authToken : "", category, forRoleId };

        Service({
            method: "post",
            url: API_ROUTES.GET_USER_REVIEWS(page),
            // url: API_ROUTES.GET_CATEGORY_REVIEWS1(page),
            data: { ...payload },
        })
            .then((res) => {
                const allReviewData = get(res, "data.data", {});
                dispatch(
                    setReview({
                        ...allReviewData,
                        requestedPage: page,
                    })
                );
                done();
            })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});

const addlReviewsLogic = createLogic({
    type: ADD_REVIEWS,
    latest: true,
    process({ action }, dispatch, done) {
        const { jobId, review, rating } = action.payload;
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", jobId, review, rating };

        Service({
            method: "post",
            url: API_ROUTES.ADD_REVIEWS,
            data: { ...payload },
        })
            .then((res) => {
                // const addReviewData = get(res, "data", {});
                done();
            })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});

export default [getCategoryReviewsLogic, getUserReviewsLogic, addlReviewsLogic];
