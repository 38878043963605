import firebase from "firebase";

const config = {
    apiKey: "AIzaSyAvS_Weige6ss0H6F4WopXYvvq8J2Q_Rr4",
    authDomain: "urbangenba-9aeff.firebaseapp.com",
    databaseURL: "https://urbangenba-9aeff.firebaseio.com",
    projectId: "urbangenba-9aeff",
    storageBucket: "urbangenba-9aeff.appspot.com",
    messagingSenderId: "508607579429",
    appId: "1:508607579429:web:6573f174623d8aa9a446cc",
    measurementId: "G-7XPNYKP32S",
};

firebase.initializeApp(config);

export default firebase;
