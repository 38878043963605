import { message } from "antd";
import { Service } from "app/config/service";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import get from "lodash/get";
import {
    gettingAllJobs,
    getAllJobsError,
    setAllJobs,
    updateJobSuccess,
    AddJobEvidence1,
    getJobEvidenceMiddleware,
    apiLoader,
    AddJobEvidenceError,
    AddJobEvidenceSuccess,
} from "./action";
import {
    ADD_NEW_ADMIN,
    GET_ALL_ADMINS,
    ADD_NEW_ADMIN_JOB,
    GET_ALL_JOBS,
    UPDATE_JOB,
    ADD_EVIDENCE_JOB,
    ADD_EVIDENCE_JOB1,
    GET_EVIDENCE_JOB,
} from "./types";

const { createLogic } = require("redux-logic");

const getAllJobsLogic = createLogic({
    type: GET_ALL_JOBS,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(gettingAllJobs());
        const { page, type } = action.payload;
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", type };
        // axios.post(API_ROUTES.GET_ALL_USERS(action.payload), payload, {
        //     // headers: { "Content-Type": "multipart/form-data" },
        // });

        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_JOBS(page),
            data: { ...payload },
        })
            .then((res) => {
                const allAdminData = get(res, "data.data", {});
                dispatch(
                    setAllJobs({
                        ...allAdminData,
                        requestedPage: page,
                    })
                );
                done();
            })
            .catch((err) => {
                dispatch(getAllJobsError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const updateJobLogic = createLogic({
    type: UPDATE_JOB,
    latest: true,
    process({ action }, dispatch, done) {
        // dispatch(gettingAllJobs());
        // const { page, type } = action.payload;

        const authToken = getItem("token");
        const payload = {
            authToken: authToken ? authToken : "",
            ...action.payload,
        };
        // axios.post(API_ROUTES.GET_ALL_USERS(action.payload), payload, {
        //     // headers: { "Content-Type": "multipart/form-data" },
        // });

        Service({
            method: "post",
            url: API_ROUTES.UPDATE_JOB,
            data: { ...payload },
        })
            .then((res) => {
                message.success("Job updated successfully!");
                dispatch(updateJobSuccess({ ...payload }));
                done();
            })
            .catch((err) => {
                // dispatch(getAllJobsError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const dynamicPayload = (data) => {
    switch (data.payload.typeName) {
        case "note":
            return `note`
            break;
        case "video":
            return `video`
            break;
        case "image":
            return `image`
            break;
        case "audio":
            return `audio`
            break;
        default:
            break;
    }
}

const AddJobEvidences = createLogic({
    type: ADD_EVIDENCE_JOB,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(apiLoader(true))
        dispatch(AddJobEvidence1());
        const authToken = getItem("token");
        const typeOfDataName = dynamicPayload(action)
        let formData = new FormData()
        formData.append("authToken", authToken ? authToken : "")
        formData.append("jobId", action.payload.jobId)
        formData.append(`${typeOfDataName}`, action.payload.value)

        Service({
            method: "post",
            url: API_ROUTES.ADD_JOB_EVIDENCE,
            data: formData,
        })
            .then((res) => {
                if(res.data?.data === null){
                    message.error(res.data?.error?.errorMsg);
                    dispatch(AddJobEvidenceError())
                    
                }else{
                    if (action.payload) dispatch(AddJobEvidence1(res));
                    dispatch(AddJobEvidenceSuccess(true))
                }
                dispatch(apiLoader(false))
                done();

            })
            .catch((err) => {
                dispatch(apiLoader(false))
                dispatch(AddJobEvidenceError())
                // dispatch(getAllJobsError(err));
                message.error("Something went wrong! When adding evidence");
                done();
            });
    },
});

const getJobEvidence = createLogic({
    type: GET_EVIDENCE_JOB,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getJobEvidenceMiddleware());
        const authToken = getItem("token");
        const payload = {
            authToken: authToken ? authToken : "",
            jobId: action.payload || ""
        }

        Service({
            method: "post",
            url: API_ROUTES.GET_JOB_EVIDENCE,
            data: { ...payload },
        })
            .then((res) => {
                // message.success("Get Job Evidence successfully!");
                const info = res && res.data.data
                if (action.payload) dispatch(getJobEvidenceMiddleware(info));
                done();
            })
            .catch((err) => {
                // dispatch(getAllJobsError(err));
                message.error("Something went wrong! When getting job evidence");
                done();
            });
    },
});

export default [getAllJobsLogic, updateJobLogic, AddJobEvidences, getJobEvidence];
