import {
    SET_ACTION_ITEM,
    SET_DASHBOARD_DETAILS,
    SET_DEVICE,
    SET_NOTIFICATION_COUNT,
    SET_ROLE,
    RESET_DETAILS,
} from "./types";
import { getItem } from "app/utils/session";
import get from "lodash/get";
const userData = getItem("user_data") ? JSON.parse(getItem("user_data")) : "";
const roleId = get(userData, "roleId", 0);
const initialState = {
    roleId: 0,
    entity: roleId && (roleId === 3 || roleId === 4) ? "JOBS" : "",
    actionItem: roleId && (roleId === 3 || roleId === 4) ? "VIEW" : "DASHBOARD",
    notificationCount: 0,
    device: {
        isMobile: false,
        isTablet: false,
        isDesktop: true,
    },
    dashboard: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLE:
            return { ...state, roleId: action.payload };
        case SET_ACTION_ITEM:
            const { entity, actionItem } = action.payload;
            return { ...state, entity, actionItem };
        case SET_NOTIFICATION_COUNT:
            return { ...state, notificationCount: action.payload };
        case SET_DEVICE:
            return { ...state, device: action.payload };
        case SET_DASHBOARD_DETAILS:
            return { ...state, dashboard: action.payload };
        case RESET_DETAILS:
            return { ...initialState };
        default:
            return { ...state };
    }
};
