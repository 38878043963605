import {
    ADDING_NEW_ADMIN,
    ADD_NEW_ADMIN_ERROR,
    NEW_ADMIN_ADDED,
    GETTING_ALL_ADMINS,
    SET_ALL_ADMINS,
    GET_ALL_ADMINS_ERROR,
    ADDING_NEW_ADMIN_JOB,
    NEW_ADMIN_JOB_ADDED,
    ADD_NEW_ADMIN_JOB_ERROR,
    EDIT_ADMIN_DATA
} from "./types";
import get from "lodash/get";
import moment from "moment";

const initialState = {
    newAdmin: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
        created: false,
    },
    allAdmins: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1,
        isUpdate: false,
    },
    newJob: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
        created: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADDING_NEW_ADMIN:
            return {
                ...state,
                newAdmin: { ...state.newAdmin, loading: true, created: false },
            };
        case NEW_ADMIN_ADDED:
            return {
                ...state,
                newAdmin: { ...state.newAdmin, loading: false, created: true },
            };
        case ADD_NEW_ADMIN_ERROR:
            return {
                ...state,
                newAdmin: { ...state.newAdmin, loading: false },
            };
        case GETTING_ALL_ADMINS:
            return {
                ...state,
                allAdmins: { ...state.allAdmins, loading: true },
            };
        case SET_ALL_ADMINS:
            const allAdminList = get(action.payload, "list", []);
            const lastPage = get(action.payload, "lastPage", 1);
            const requestedPage = get(action.payload, "requestedPage", 1);
            const tableData = allAdminList.map((admin) => ({
                ...admin,
                name: `${admin.firstName} ${admin.lastName}`,
                creationDate: moment(admin.creationDate).format("DD/MM/YYYY"),
            }));

            const allData =
                // requestedPage === 1
                //     ?
                tableData;
            // : [...state.allAdmins.data, ...tableData];

            return {
                ...state,
                allAdmins: {
                    ...state.allAdmins,
                    loading: false,
                    data: allData,
                    lastPage: lastPage,
                },
            };
        case GET_ALL_ADMINS_ERROR:
            return {
                ...state,
                allAdmins: { ...state.allAdmins, loading: false },
            };
        case ADDING_NEW_ADMIN_JOB:
            return {
                ...state,
                newJob: { ...state.newJob, loading: true, created: false },
            };
        case NEW_ADMIN_JOB_ADDED:
            return {
                ...state,
                newJob: { ...state.newJob, loading: false, created: true },
            };
        case ADD_NEW_ADMIN_JOB_ERROR:
            return {
                ...state,
                newJob: { ...state.newJob, loading: false },
            };
        case EDIT_ADMIN_DATA:
            return {
                ...state,
                newAdmin:{
                    ...state.newAdmin, 
                    loading: false,
                },
                allAdmins: { 
                    ...state.allAdmins, 
                    loading: false,
                    isUpdate: action.payload.isUpdate,
                },
            };
        default:
            return { ...state };
    }
};
