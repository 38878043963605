export const RESET_STATE = "RESET_STATE";
export const REMOVE_DATA = "REMOVE_DATA";

export const ADD_BRAND_BEGIN = "ADD_BRAND_BEGIN";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_BEGIN_SUCCESS";
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL";
export const ADD_BRAND_MIDDLEWARE = "ADD_BRAND_MIDDLEWARE";

export const EDIT_BRAND = "EDIT_BRAND";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCCESS";
export const EDIT_BRAND_FAIL = "EDIT_BRAND_FAIL";
export const EDIT_BRAND_MIDDLEWARE = "EDIT_BRAND_MIDDLEWARE";

export const ADD_PRODUCT_SERIES_BEGIN = "ADD_PRODUCT_SERIES_BEGIN";
export const ADD_PRODUCT_SERIES_SUCCESS = "ADD_PRODUCT_SERIES_SUCCESS";
export const ADD_PRODUCT_SERIES_PAYLOAD = "ADD_PRODUCT_SERIES_PAYLOAD";
export const ADD_PRODUCT_SERIES_FAIL = "ADD_PRODUCT_SERIES_FAIL";
export const ADD_PRODUCT_SERIES_MIDDLEWARE_SUCCESS = "ADD_PRODUCT_SERIES_MIDDLEWARE_SUCCESS";

export const EDIT_PRODUCT_SERIES_PAYLOAD = "EDIT_PRODUCT_SERIES_PAYLOAD";
export const EDIT_PRODUCT_SERIES_SUCCESS = "EDIT_PRODUCT_SERIES_SUCCESS";
    
export const ADD_MODEL_BEGIN = "ADD_MODEL_BEGIN";
export const ADD_MODEL_PAYLOAD = "ADD_MODEL_PAYLOAD";
export const ADD_MODEL_FAIL = "ADD_MODEL_FAIL";
export const ADD_MODEL_MIDDLEWARE_SUCCESS = "ADD_MODEL_MIDDLEWARE_SUCCESS";

export const EDIT_MODEL_PAYLOAD = "EDIT_MODEL_PAYLOAD";
export const EDIT_MODEL_SUCCESS = "EDIT_MODEL_SUCCESS";

export const GET_MODEL_BEGIN = "GET_MODEL_BEGIN";
export const GET_MODEL_PAYLOAD = "GET_MODEL_PAYLOAD";
export const GET_MODEL_FAIL = "GET_MODEL_FAIL";
export const GET_MODEL_MIDDLEWARE_SUCCESS = "GET_MODEL_MIDDLEWARE_SUCCESS";

export const GET_BRAND_BEGIN = "GET_BRAND_BEGIN";
export const GET_BRAND_PAYLOAD = "GET_BRAND_PAYLOAD";
export const GET_BRAND_FAIL = "GET_BRAND_FAIL";
export const GET_BRAND_MIDDLEWARE = "GET_BRAND_MIDDLEWARE";

export const GET_PRODUCT_SERIES_BEGIN = "GET_PRODUCT_SERIES_BEGIN";
export const GET_PRODUCT_SERIES_SUCCESS = "GET_PRODUCT_SERIES_SUCCESS";
export const GET_PRODUCT_SERIES_PAYLOAD = "GET_PRODUCT_SERIES_PAYLOAD";
export const GET_PRODUCT_SERIES_FAIL = "GET_PRODUCT_SERIES_FAIL";
export const GET_PRODUCT_SERIES_MIDDLEWARE_SUCCESS = "GET_PRODUCT_SERIES_MIDDLEWARE_SUCCESS";






