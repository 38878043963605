import { message } from "antd";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";
import {
    addingNewServiceProvider,
    addNewServiceProviderError,
    newServiceProviderAdded,
    getAllServiceProvider,
    gettingAllServiceProvider,
    setAllServiceProvider,
    getAllServiceProviderError,
} from "./action";
import { ADD_NEW_SERVICE_PROVIDER, EDIT_SERVICE_PROVIDER_MIDDLEWARE, GET_ALL_SERVICE_PROVIDERS } from "./types";

import get from "lodash/get";
import { Service } from "app/config/service";
import { setActionItem } from "../home/action";
import {
    addingNewAdmin,
    addNewAdminError,
    editAdminAction,
} from "../admin/action";
const { createLogic } = require("redux-logic");

const addNewServiceProviderLogic = createLogic({
    type: ADD_NEW_SERVICE_PROVIDER,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewServiceProvider());
        const formData = new FormData();
        const {
            firstName,
            lastName,
            email,
            contact,
            address,
            country,
            city,
            state,
            username,
            password,
            profilePic,
            allCategories,
            globalVisibility,
            type,
            tool
        } = action.payload;

        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("emailId", email);
        formData.append("phoneNumber", contact);
        formData.append("address", address);
        formData.append("country", country);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("username", username);
        formData.append("password", password);
        formData.append("forRoleId", 3);
        formData.append("profilePic", profilePic);
        formData.append("globalVisibility", globalVisibility);
        formData.append("tool", tool);
        formData.append("type", type);
        formData.append("businessName", "");
        formData.append("businessOwnerId", "");
        formData.append("businessCertificate", "");

        formData.append("category", JSON.stringify({ allCategory: allCategories }));

        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        // axios.post(API_ROUTES.ADDUSER, formData, {
        //     headers: { "Content-Type": "multipart/form-data" },
        // });

        Service({
            method: "post",
            url: API_ROUTES.ADDUSER,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            dispatch(newServiceProviderAdded());
            if(res?.data?.error?.errorMsg){
                message.error(res?.data?.error?.errorMsg);
            }else{
                dispatch(setActionItem("SERVICE_PROVIDER", "VIEW"));
                message.success("Service Provider Created Successfully!");
            }
            done();
        }).catch((err) => {
            dispatch(addNewServiceProviderError(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const updateServiceProviderLogic = createLogic({
    type: EDIT_SERVICE_PROVIDER_MIDDLEWARE,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewServiceProvider());
        const formData = new FormData();
        const token = getItem("token");
        const { profilePic, authToken, userId} = action.payload;

        // let devices = {
        //     "allTools": allCategories
        // }
        // formData.append("tool", JSON.stringify(devices));
        // formData.append("category", JSON.stringify({ allCategory: allCategories }));
        formData.append("authToken", token ? token : "");

        console.log(`Object.keys(action.payload).length`, Object.keys(action.payload).length)
        
        if(profilePic){
            formData.append("profilePic", profilePic);
            // formData.append("authToken", authToken);
            formData.append("userId", userId);
        }

        if(profilePic && Object.keys(action.payload).length < 2){
            Service({
                method: "post",
                url: API_ROUTES.UPDATE_USER_CONTENT,
                data: formData,
            }).then((res) => {
                dispatch(newServiceProviderAdded());
                // dispatch(getAllServiceProvider(1));
                // dispatch(setActionItem("SERVICE_PROVIDER", "VIEW"));
                dispatch(gettingAllServiceProvider())

                message.success(" Profile Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewServiceProviderError(err));
                done();
            });
        }
    
        if(!profilePic && Object.keys(action.payload).length > 2){
            delete action.payload.profilePic
            Service({
                method: "post",
                url: API_ROUTES.EDITUSER,
                data: action.payload
            }).then((res) => {
                dispatch(newServiceProviderAdded());
                dispatch(gettingAllServiceProvider())
                message.success("Service Provider Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewServiceProviderError(err));
                message.error("Something went wrong!");
                done();
            });
        }

        if(profilePic && Object.keys(action.payload).length > 2){

            Service({
                method: "post",
                url: API_ROUTES.UPDATE_USER_CONTENT,
                data: formData,
            }).then((res) => {
                done();
            }).catch((err) => {
                dispatch(addNewServiceProviderError(err));
                done();
            });

            delete action.payload.profilePic
            Service({
                method: "post",
                url: API_ROUTES.EDITUSER,
                data: action.payload
            }).then((res) => {
                dispatch(newServiceProviderAdded());
                dispatch(gettingAllServiceProvider())
                message.success("Service Provider Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewServiceProviderError(err));
                message.error("Something went wrong!");
                done();
            });
        }
    },
});

const getAllServiceProvidersLogic = createLogic({
    type: GET_ALL_SERVICE_PROVIDERS,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(gettingAllServiceProvider());
        const authToken = getItem("token");

        const payload = { authToken: authToken ? authToken : "", forRoleId: 3 };
        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_USERS(action.payload),
            data: { ...payload },
        })
            .then((res) => {
                const allServiceProviderData = get(res, "data.data", {});
                dispatch(
                    setAllServiceProvider({
                        ...allServiceProviderData,
                        requestedPage: action.payload,
                    })
                );
                done();
            })
            .catch((err) => {
                dispatch(getAllServiceProviderError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

export default [addNewServiceProviderLogic, getAllServiceProvidersLogic, updateServiceProviderLogic];
