const APP_ROUTES = {
    LANDING: "/",
    LOGIN: "/login",
    HOME: "/home",
    PASSWORD: "/password",
    // NOTIFICATION: "/home/notification",
};

// export const HOME_ROUTES = {
//     CUSTOMER: { ADD: "/customer/add", VIEW: "/customer/view" },
//     SERVICE_PROVIDER: {
//         ADD: "/service-provider/add",
//         VIEW: "/service-provider/view",
//     },
//     EXPERTS: { ADD: "/expert/add", VIEW: "/expert/view" },
//     ADMIN: { ADD: "/admin/add", VIEW: "/admin/view" },
//     CATEGORY: { ADD: "/category/add", VIEW: "/category/view" },
//     TRAININGS: { ADD: "/training/add", VIEW: "/training/view" },
//     JOBS: { ADD: "/jobs/add", VIEW: "/jobs/view" },
// };

export default APP_ROUTES;
