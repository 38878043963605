export const ADD_NEW_ADMIN = "ADD_NEW_ADMIN";
export const EDIT_ADMIN_MIDDLEWARE = "EDIT_ADMIN_MIDDLEWARE";
export const EDIT_ADMIN_DATA = "EDIT_ADMIN_DATA";
export const ADDING_NEW_ADMIN = "ADDING_NEW_ADMIN";
export const NEW_ADMIN_ADDED = "NEW_ADMIN_ADDED";
export const ADD_NEW_ADMIN_ERROR = "ADD_NEW_ADMIN_ERROR";

export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const GETTING_ALL_ADMINS = "GETTING_ALL_ADMINS";
export const SET_ALL_ADMINS = "SET_ALL_ADMINS";
export const GET_ALL_ADMINS_ERROR = "GET_ALL_ADMINS_ERROR";

export const ADD_NEW_ADMIN_JOB = "ADD_NEW_ADMIN_JOB";
export const ADDING_NEW_ADMIN_JOB = "ADDING_NEW_ADMIN_JOB";
export const NEW_ADMIN_JOB_ADDED = "NEW_ADMIN_JOB_ADDED";
export const ADD_NEW_ADMIN_JOB_ERROR = "ADD_NEW_ADMIN_JOB_ERROR";
