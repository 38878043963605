import { message } from "antd";
import { Service } from "app/config/service";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import get from "lodash/get";
import { setActionItem } from "../home/action";
import {
    gettingAllAdmins,
    addingNewAdmin,
    addNewAdminError,
    getAllAdminsError,
    newAdminAdded,
    setAllAdmins,
    newAdminJobAdded,
    addNewAdminJobError,
    addingNewAdminJob,
    editAdminAction,
} from "./action";
import { ADD_NEW_ADMIN, GET_ALL_ADMINS, ADD_NEW_ADMIN_JOB, EDIT_ADMIN_MIDDLEWARE } from "./types";

const { createLogic } = require("redux-logic");

const addNewAdminLogic = createLogic({
    type: ADD_NEW_ADMIN,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewAdmin());
        const formData = new FormData();
        const {
            firstName,
            lastName,
            email,
            contact,
            address,
            country,
            city,
            state,
            username,
            password,
            profilePic,
            tool,
            type
        } = action.payload;

        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("emailId", email);
        formData.append("phoneNumber", contact);
        formData.append("address", address);
        formData.append("country", country);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("username", username);
        formData.append("password", password);
        formData.append("forRoleId", 2);
        formData.append("profilePic", profilePic);
        formData.append("tool", tool);
        formData.append("type", type);
        // formData.append("category", "");

        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        // axios
        //     .post(API_ROUTES.ADDUSER, formData, {
        //         headers: { "Content-Type": "multipart/form-data" },
        //     })

        Service({
            method: "post",
            url: API_ROUTES.ADDUSER,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            dispatch(newAdminAdded());
            if(res?.data?.error?.errorMsg){
                message.error(res?.data?.error?.errorMsg);
            }else{
                dispatch(setActionItem("ADMIN", "VIEW"));
                message.success("Admin created Successfully!");
            }
            done();
        }).catch((err) => {
            dispatch(addNewAdminError(err));
            message.error("Something went wrong!");
            done();
        });
    },
});

const updateAdminLogic = createLogic({
    type: EDIT_ADMIN_MIDDLEWARE,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewAdmin());
        const formData = new FormData();
        const { allCategories, profilePic, authToken, userId} = action.payload;

        let devices = {
            "allTools": allCategories
        }
        formData.append("tool", JSON.stringify(devices));
        // const token = getItem("token");
        // formData.append("authToken", token ? token : "");

        if(profilePic){
            formData.append("profilePic", profilePic);
            formData.append("authToken", authToken);
            formData.append("userId", userId);
        }
        if(profilePic && Object.keys(action.payload).length < 5){
            Service({
                method: "post",
                url: API_ROUTES.UPDATE_USER_CONTENT,
                data: formData,
            }).then((res) => {
                dispatch(addingNewAdmin());
                dispatch(editAdminAction({isUpdate: true}));
                message.success("Admin Profile Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewAdminError(err));
                done();
            });
        }
        if(!profilePic && Object.keys(action.payload).length > 2){
            delete action.payload.profilePic
            Service({
                method: "post",
                url: API_ROUTES.EDITUSER,
                data: action.payload,
            }).then((res) => {
                dispatch(addingNewAdmin());
                dispatch(editAdminAction({isUpdate: true}));
                message.success("Admin Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewAdminError(err));
                message.error("Something went wrong!");
                done();
            });
        }

        if(profilePic && Object.keys(action.payload).length > 4){
            Service({
                method: "post",
                url: API_ROUTES.UPDATE_USER_CONTENT,
                data: formData,
            }).then((res) => {
                done();
            }).catch((err) => {
                dispatch(addNewAdminError(err));
                done();
            });

            delete action.payload.profilePic
            Service({
                method: "post",
                url: API_ROUTES.EDITUSER,
                data: action.payload,
            }).then((res) => {
                dispatch(addingNewAdmin());
                dispatch(editAdminAction({isUpdate: true}));
                message.success("Admin Update Successfully!");
                done();
            }).catch((err) => {
                dispatch(addNewAdminError(err));
                message.error("Something went wrong!");
                done();
            });
        }
    }
});

const getAllAdminsLogic = createLogic({
    type: GET_ALL_ADMINS,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(gettingAllAdmins());

        const authToken = getItem("token");

        const payload = { authToken: authToken ? authToken : "", forRoleId: 2 };
        // axios.post(API_ROUTES.GET_ALL_USERS(action.payload), payload, {
        //     // headers: { "Content-Type": "multipart/form-data" },
        // });

        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_USERS(action.payload),
            data: { ...payload },
        })
            .then((res) => {
                const allAdminData = get(res, "data.data", {});
                dispatch(
                    setAllAdmins({
                        ...allAdminData,
                        requestedPage: action.payload,
                    })
                );
                done();
            })
            .catch((err) => {
                dispatch(getAllAdminsError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const addNewAdminJobLogic = createLogic({
    type: ADD_NEW_ADMIN_JOB,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewAdminJob());
        const token = getItem("token");
        // axios.post(API_ROUTES.ADD_JOB, {
        //     ...action.payload,
        //     authToken: token,
        // });
        Service({
            method: "post",
            url: API_ROUTES.ADD_JOB,
            data: {
                ...action.payload,
                authToken: token,
            },
        })
            .then(() => {
                dispatch(newAdminJobAdded());
                message.success("Job created Successfully!");
                done();
            })
            .catch((err) => {
                dispatch(addNewAdminJobError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

export default [addNewAdminLogic, getAllAdminsLogic, addNewAdminJobLogic, updateAdminLogic];
