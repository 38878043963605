import { message } from "antd";
import { Service } from "app/config/service";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import get from "lodash/get";
import {
    addingNewCategory,
    addNewCategoryError,
    getAllCategoriesError,
    getPageCategoriesError,
    gettingAllCategories,
    gettingPageCategories,
    newCategoryAdded,
    setAllCategories,
    setPageCategories,
    setAllSubCategories,
    getCategoryRating1
} from "./action";
import {
    ADD_NEW_CATEGORY,
    GET_ALL_CATEGORIES,
    GET_PAGE_CATEGORIES,
    GET_CATEGORY_REVIEW,
} from "./types";

const { createLogic } = require("redux-logic");

const addNewCategoryLogic = createLogic({
    type: ADD_NEW_CATEGORY,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(addingNewCategory());
        const formData = new FormData();
        const {
            name,
            shortDescription,
            longDescription,
            profilePic,
            parentId,
        } = action.payload;

        formData.append("name", name);
        formData.append("shortDescription", shortDescription);
        formData.append("longDescription", longDescription);
        formData.append("profilePic", profilePic);
        if (parentId) formData.append("parentId", parentId);

        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        
        Service({
            method: "post",
            url: API_ROUTES.ADD_CATEGORY,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                dispatch(newCategoryAdded());
                message.success(
                    `${parentId ? "Sub-category" : "Category"
                    } created Successfully!`
                );
                done();
            })
            .catch((err) => {
                dispatch(addNewCategoryError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const getAllCategoriesLogic = createLogic({
    type: GET_ALL_CATEGORIES,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(gettingAllCategories());
        const parentId = action.payload ? action.payload : "";
        const authToken = getItem("token");

        const payload = { authToken: authToken ? authToken : "", parentId };
        // axios.post(API_ROUTES.GET_CATEGORIES, payload, {
        //     // headers: { "Content-Type": "multipart/form-data" },
        // });
        Service({
            method: "post",
            url: API_ROUTES.GET_CATEGORIES,
            data: { ...payload },
        })
            .then((res) => {
                const list = get(res, "data.data.list", []);
                if (action.payload) dispatch(setAllSubCategories(list));
                else dispatch(setAllCategories(list));

                done();
            })
            .catch((err) => {
                dispatch(getAllCategoriesError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const getPageCategoriesLogic = createLogic({
    type: GET_PAGE_CATEGORIES,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(gettingPageCategories());

        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", forRoleId: 0 };
      
        Service({
            method: "post",
            url: API_ROUTES.GET_PAGINATED_CATEGORIES(action.payload),
            data: { ...payload },
        })
            .then((res) => {
                const paginatedCategories = get(res, "data.data", {});
                dispatch(
                    setPageCategories({
                        ...paginatedCategories,
                        requestedPage: action.payload,
                    })
                );
                done();
            })
            .catch((err) => {
                dispatch(getPageCategoriesError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const dynamicPayload = (data, auth) => {
    switch (data.payload.typeName) {
        case "category":
            return { authToken: auth ? auth : "", categoryId: data.payload.value }
            break;
        case "subCategory":
            return { authToken: auth ? auth : "", categoryId: data.payload.value }
            break;
        case "customer":
            return { authToken: auth ? auth : "", userId: data.payload.value }
            break;
        case "admin":
            return { authToken: auth ? auth : "", userId: data.payload.value }
            break;
        case "superAdmin":
            return { authToken: auth ? auth : "", userId: data.payload.value }
            break;
        case "serviceProvider":
            return { authToken: auth ? auth : "", userId: data.payload.value }
            break;
        default:
            break;
    }
}

const getCategoryReviewsInfoLogic = createLogic({
    type: GET_CATEGORY_REVIEW,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getCategoryRating1());
        const authToken = getItem("token");
        let payload 
        if(action.payload?.own && action.payload?.own === true){
            payload = {"authToken": authToken ? authToken : ""}
        }else{
         payload = dynamicPayload(action, authToken)
        }

        Service({
            method: "post",
            url: API_ROUTES.GET_CATEGORY_RATING,
            data: { ...payload },
        })
            .then((res) => {
                const list = res
                if (action.payload) dispatch(getCategoryRating1(list));
                done();
            })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});

export default [
    addNewCategoryLogic,
    getAllCategoriesLogic,
    getPageCategoriesLogic,
    getCategoryReviewsInfoLogic,
];
