import {
    ADD_NEW_ADMIN,
    ADDING_NEW_ADMIN,
    ADD_NEW_ADMIN_ERROR,
    NEW_ADMIN_ADDED,
    GETTING_ALL_ADMINS,
    GET_ALL_ADMINS,
    GET_ALL_ADMINS_ERROR,
    SET_ALL_ADMINS,
    ADD_NEW_ADMIN_JOB,
    ADDING_NEW_ADMIN_JOB,
    ADD_NEW_ADMIN_JOB_ERROR,
    NEW_ADMIN_JOB_ADDED,
    EDIT_ADMIN_MIDDLEWARE,
    EDIT_ADMIN_DATA
} from "./types";

export const addNewAdmin = (payload) => ({
    type: ADD_NEW_ADMIN,
    payload,
});
export const addingNewAdmin = () => ({
    type: ADDING_NEW_ADMIN,
});
export const newAdminAdded = (payload) => ({
    type: NEW_ADMIN_ADDED,
    payload,
});
export const addNewAdminError = (err) => ({
    type: ADD_NEW_ADMIN_ERROR,
    err,
});

export const editAdminDataAction = (payload) => ({
    type: EDIT_ADMIN_MIDDLEWARE,
    payload,
});

export const editAdminAction = (payload) => ({
    type: EDIT_ADMIN_DATA,
    payload,
});

export const getAllAdmins = (payload) => ({
    type: GET_ALL_ADMINS,
    payload,
});
export const gettingAllAdmins = () => ({
    type: GETTING_ALL_ADMINS,
});
export const setAllAdmins = (payload) => ({
    type: SET_ALL_ADMINS,
    payload,
});
export const getAllAdminsError = (err) => ({
    type: GET_ALL_ADMINS_ERROR,
    err,
});

export const addNewAdminJob = (payload) => ({
    type: ADD_NEW_ADMIN_JOB,
    payload,
});
export const addingNewAdminJob = () => ({
    type: ADDING_NEW_ADMIN_JOB,
});
export const newAdminJobAdded = (payload) => ({
    type: NEW_ADMIN_JOB_ADDED,
    payload,
});
export const addNewAdminJobError = (err) => ({
    type: ADD_NEW_ADMIN_JOB_ERROR,
    err,
});
