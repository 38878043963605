import {
    ADDING_NEW_CATEGORY,
    ADD_NEW_CATEGORY_ERROR,
    GETTING_ALL_CATEGORIES,
    GET_ALL_CATEGORIES_ERROR,
    NEW_CATEGORY_ADDED,
    SET_ALL_CATEGORIES,
    GETTING_PAGE_CATEGORIES,
    GET_PAGE_CATEGORIES_ERROR,
    SET_PAGE_CATEGORIES,
    SET_ALL_SUBCATEGORIES,
    SET_NOTIFICATION_CATEGORY,
    GET_CATEGORY_REVIEW,
    GET_CATEGORY_REVIEW1,
    REMOVE_CATEGORY_REVIEW,

} from "./types";
import get from "lodash/get";
import moment from "moment";

const initialState = {
    newCategory: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
        created: false,
    },
    allCategories: { loading: false, isError: false, errMsg: "", data: [] },
    allSubCategories: { data: [] },
    paginatedCategories: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1,
    },
    notificationCategory: {
        loading: false,
        isError: false,
        errMsg: "",
        data: {},
    },
    categoryReview: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADDING_NEW_CATEGORY:
            return {
                ...state,
                newCategory: {
                    ...state.newCategory,
                    loading: true,
                    created: false,
                },
            };
        case NEW_CATEGORY_ADDED:
            return {
                ...state,
                newCategory: {
                    ...state.newCategory,
                    loading: false,
                    created: true,
                },
            };
        case ADD_NEW_CATEGORY_ERROR:
            return {
                ...state,
                newCategory: { ...state.newCategory, loading: false },
            };
        case GETTING_ALL_CATEGORIES:
            return {
                ...state,
                allCategories: { ...state.allCategories, loading: true },
            };
        case SET_ALL_CATEGORIES:
            return {
                ...state,
                allCategories: {
                    ...state.allCategories,
                    loading: false,
                    data: action.payload,
                },
            };
        case GET_ALL_CATEGORIES_ERROR:
            return {
                ...state,
                allCategories: { ...state.allCategories, loading: false },
            };
        case GETTING_PAGE_CATEGORIES:
            return {
                ...state,
                paginatedCategories: {
                    ...state.paginatedCategories,
                    loading: true,
                },
            };
        case SET_PAGE_CATEGORIES:
            const pageCategoryList = get(action.payload, "list", []);
            const lastPage = get(action.payload, "lastPage", 1);
            const requestedPage = get(action.payload, "requestedPage", 1);
            const tableData = pageCategoryList.map((category) => ({
                ...category,
                // name: `${category.firstName} `,
                // creationDate: moment(category.creationDate).format(
                //     "DD/MM/YYYY"
                // ),
            }));

            const allData =
                // requestedPage === 1
                //     ?
                tableData;
            // : [...state.paginatedCategories.data, ...tableData];

            return {
                ...state,
                paginatedCategories: {
                    ...state.paginatedCategories,
                    loading: false,
                    data: allData,
                    lastPage: lastPage,
                },
            };
        case GET_PAGE_CATEGORIES_ERROR:
            return {
                ...state,
                paginatedCategories: {
                    ...state.paginatedCategories,
                    loading: false,
                },
            };
        case SET_ALL_SUBCATEGORIES:
            return {
                ...state,
                allSubCategories: {
                    ...state.allSubCategories,
                    data: action.payload,
                },
            };
        case SET_NOTIFICATION_CATEGORY:
            return {
                ...state,
                notificationCategory: {
                    ...state.notificationCategory,
                    data: action.payload,
                },
            };
        case GET_CATEGORY_REVIEW1:
            return {
                ...state,
                categoryReview: action.payload
            };
        case REMOVE_CATEGORY_REVIEW:
            return {
                ...state,
                categoryReview: null
            };
        default:
            return { ...state };
    }
};
