import get from "lodash/get";
import ReactGA from 'react-ga';

export const getItem = (key) => {
    const item = window.localStorage.getItem(key);
    return item ? item : "";
};

export const setItem = (key, value) => {
    window.localStorage.setItem(key, value);
};

export const removeItem = (key) => {
    window.localStorage.removeItem(key);
};

export const setNonLoginSession = (payload) => {
    const nonLoginAuthToken = get(payload, "data.data.authToken", "");
    setItem("nonLoginAuthToken", nonLoginAuthToken);
};

export const setLoginSession = (payload) => {
    const token = get(payload, "data.data.authToken", "");
    const refesh_token = get(payload, "data.data.refreshToken", "");
    const user_exists = get(payload, "data.data.userExists", false);
    const user_data = get(payload, "data.data.userProfile", {});

    setItem("token", token);
    setItem("refesh_token", refesh_token);
    setItem("user_exists", user_exists);
    setItem("user_data", JSON.stringify(user_data));
};

export const resetSession = () => {
    removeItem("token");
    removeItem("refesh_token");
    removeItem("user_exists");
    removeItem("user_data");
};

export const googleAnalytics = (category, action) => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    if (userData.username) {
      ReactGA.set({ dimension1: userData.username });
      ReactGA.set({
        userId: userData.username,
      });
    }
    if(category && action) {
      ReactGA.event({
        category: category || "VIEW",
        action: action
      });
    }
};