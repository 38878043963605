export const ADD_TRAINING = "ADD_TRAINING";
export const GET_TRAINING = "GET_TRAINING";
export const SET_TRAINING = "SET_TRAINING";
export const ADD_CONTENT = "ADD_CONTENT";
export const GET_ALL_TRAINING = "GET_ALL_TRAINING";
export const SET_ALL_TRAINING = "SET_ALL_TRAINING";
export const GET_ALL_CHAPTERS = "GET_ALL_CHAPTERS";
export const SET_ALL_CHAPTERS = "SET_ALL_CHAPTERS";
export const SET_TRAINING_VIEW = "SET_TRAINING_VIEW";
export const SET_ALL_TRAINING_WITHOUT_FILTER = "SET_ALL_TRAINING_WITHOUT_FILTER";

export const GET_TRAINING_SERVER_PROVIDER_LISTS_REQUEST = "GET_TRAINING_SERVER_PROVIDER_LISTS_REQUEST";
export const GET_TRAINING_SERVER_PROVIDER_LISTS_PAYLOAD = "GET_TRAINING_SERVER_PROVIDER_LISTS_PAYLOAD";
export const GET_TRAINING_SERVER_PROVIDER_LISTS_MIDDLEWARE_SUCCESS = "GET_TRAINING_SERVER_PROVIDER_LISTS_MIDDLEWARE_SUCCESS";
export const GET_TRAINING_SERVER_PROVIDER_LISTS_FAIL = "GET_TRAINING_SERVER_PROVIDER_LISTS_FAIL";
export const GET_TRAINING_USER_LISTS_REQUEST = "GET_TRAINING_USER_LISTS_REQUEST";
export const GET_TRAINING_USER_LISTS_PAYLOAD = "GET_TRAINING_USER_LISTS_PAYLOAD";
export const GET_TRAINING_USER_LISTS_MIDDLEWARE_SUCCESS = "GET_TRAINING_USER_LISTS_MIDDLEWARE_SUCCESS";
export const GET_TRAINING_USER_LISTS_FAIL = "GET_TRAINING_USER_LISTS_FAIL";
export const GET_ALL_CONTENT_DATA = "GET_ALL_CONTENT_DATA";
export const SET_ALL_CONTENT_DATA = "SET_ALL_CONTENT_DATA";
export const SET_ALL_CONTENT_DATA_LOADING = "SET_ALL_CONTENT_DATA_LOADING";
export const REMOVE_CONTENT_DATA = "REMOVE_CONTENT_DATA";
export const UPDATE_TRAINING_STATUS = "UPDATE_TRAINING_STATUS";
export const TRAINING_STATUS_UPDATE_SUCCESS = "TRAINING_STATUS_UPDATE_SUCCESS";

export const GET_ALL_CONTENT_DATA_REQUEST = "GET_ALL_CONTENT_DATA_REQUEST";
export const GET_ALL_CONTENT_DATA_PAYLOAD = "GET_ALL_CONTENT_DATA_PAYLOAD";
export const GET_ALL_CONTENT_DATA_FAIL = "GET_ALL_CONTENT_DATA_FAIL";
export const GET_ALL_CONTENT_DATA_MIDDLEWARE_SUCCESS = "GET_ALL_CONTENT_DATA_MIDDLEWARE_SUCCESS";

