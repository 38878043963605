import { message } from "antd";
import { Service } from "app/config/service";
import API_ROUTES from "app/constants/routes/api";
import { getItem } from "app/utils/session";

import get from "lodash/get";
import {
    setTraining,
    setAllTraining,
    setTrainingView,
    setAllTrainingWithoutFilter,
    setChapters,
    getTrainingServiceProviderBegin,
    getTrainingServiceProviderMiddleware,
    getTrainingServiceProviderFail,
    setContentData,
    getTrainingUserBegin,
    getTrainingUserMiddleware,
    getTrainingUserFail,
    getTrainingMediaBegin,
    getTrainingMediaFail,
    getTrainingMediaMiddleware,
    updateTrainingStatusSuccess,
} from "./action";

import {
    ADD_TRAINING,
    ADD_CONTENT,
    GET_ALL_TRAINING,
    GET_ALL_CHAPTERS,
    GET_TRAINING_SERVER_PROVIDER_LISTS_PAYLOAD,
    GET_ALL_CONTENT_DATA,
    GET_TRAINING_USER_LISTS_PAYLOAD,
    GET_ALL_CONTENT_DATA_PAYLOAD,
    UPDATE_TRAINING_STATUS,
} from "./types";

const { createLogic } = require("redux-logic");

const addTrainingLogic = createLogic({
    type: ADD_TRAINING,
    latest: true,
    process({ action }, dispatch, done) {
        const authToken = getItem("token");
        // const payload = { authToken: authToken ? authToken : "", ...action.payload };

        const formData = new FormData();
        const {
            category,
            longDescription,
            name,
            shortDescription,
            subcategory,
            training,
            image,
        } = action.payload;

        formData.append("category", category);
        formData.append("longDescription", longDescription);
        formData.append("name", name);
        formData.append("subCategory", subcategory);
        formData.append("shortDescription", shortDescription);
        formData.append("training", JSON.stringify(training));
        formData.append("image", image);

        const token = getItem("token");
        formData.append("authToken", token ? token : "");

        Service({
            method: "post",
            url: API_ROUTES.ADD_TRAINING,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            const trainingResult = get(res, "data.data", {});
            if (trainingResult) {
                message.success("Training created successfully.");
                dispatch(
                    setTraining({
                        ...trainingResult,
                    })
                );
            } else {
                message.error("Something went wrong!");
            }
            done();
        })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});

const addContentLogic = createLogic({
    type: ADD_CONTENT,
    latest: true,
    process({ action }, dispatch, done) {
        const formData = new FormData();
        const {
            topicId,
            name,
            content,
            type
        } = action.payload;

        formData.append("topicId", topicId);
        formData.append(`${type === "video" ? "videoName" : "pdfName"}`, name);
        formData.append(`${type === "video" ? "video" : "pdf"}`, content);

        const token = getItem("token");
        formData.append("authToken", token ? token : "");
        // console.log('called')
        Service({
            method: "post",
            url: API_ROUTES.ADD_CONTENT(type),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            const trainingResult = get(res, "data.data", {});
            // dispatch(
            //     setTraining({
            //         ...trainingResult,
            //     })
            // );
            //   console.log('trainingResult :>> ', trainingResult);
            done();
        })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});
const getAllTrainingDataLogic = createLogic({
    type: GET_ALL_TRAINING,
    latest: true,
    process({ action }, dispatch, done) {
        // dispatch(gettingAllServiceProvider());
        //  console.log('called :>> ');
        const { pageNo, category, subCategory } = action.payload
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", category: category ? category : null, subCategory: subCategory ? subCategory : null };
        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_TRAINING(pageNo),
            data: { ...payload },
        })
            .then((res) => {
                const allTrainingData = get(res, "data.data", {});

                dispatch(
                    setAllTraining({
                        ...allTrainingData,
                        requestedPage: pageNo,
                    })
                );
                const withoutFilter = category ? false : subCategory ? false : true;
                if (withoutFilter) {
                    dispatch(
                        setAllTrainingWithoutFilter({
                            ...allTrainingData,
                        })
                    );
                }
                done();
            })
            .catch((err) => {
                // dispatch(getAllServiceProviderError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const getAllChapterDataLogic = createLogic({
    type: GET_ALL_CHAPTERS,
    latest: true,
    process({ action }, dispatch, done) {
        // dispatch(gettingAllServiceProvider());
        // console.log('called :>> ');
        const authToken = getItem("token");

        const payload = { authToken: authToken ? authToken : "", trainingId: action.payload.trainingId };
        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_CHAPTERS,
            data: { ...payload },
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                const chapterData = get(res, "data.data", {});
                // console.log("IN MIDDLEWARE", chapterData.chapters);
                dispatch(
                    setChapters({
                        chapters: chapterData.chapters
                    })
                );
                done();
            })
            .catch((err) => {
                // dispatch(getAllServiceProviderError(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const getAllTrainingSPLogic = createLogic({
    type: GET_TRAINING_SERVER_PROVIDER_LISTS_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getTrainingServiceProviderBegin());
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "" };

        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_TRAINING_SP,
            data: { ...payload },
        })
            .then((res) => {
                const allTrainingSP = get(res, "data.data", {});
                dispatch(
                    getTrainingServiceProviderMiddleware(allTrainingSP)
                );

                done();
            })
            .catch((err) => {
                dispatch(getTrainingServiceProviderFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const getUserTrainingLogic = createLogic({
    type: GET_TRAINING_USER_LISTS_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        let { type, page } = action.payload
        dispatch(getTrainingUserBegin());
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", type: type };
        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_USER_TRAINING(page),
            data: { ...payload },
        })
            .then((res) => {
                const allTraining = get(res, "data.data", {});
                //  console.log('allTraining', allTraining)
                let info = {
                    res: allTraining,
                    type: type
                }
                dispatch(
                    getTrainingUserMiddleware(info)
                );

                done();
            })
            .catch((err) => {
                dispatch(getTrainingUserFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

let obj = {}
const getAllMediaContentLogic = createLogic({
    type: GET_ALL_CONTENT_DATA_PAYLOAD,
    latest: true,
    process({ action }, dispatch, done) {
        dispatch(getTrainingMediaBegin());
        let { topicId } = action.payload
        let authToken = getItem("token");
        let payload = { authToken: authToken ? authToken : "", topicId: topicId };

        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_CONTENT_DATA,
            data: { ...payload, type: "VIDEO" },
        })
            .then((res) => {
                const videoData = get(res, "data.data.list", {});
                if (videoData.length) {
                    obj[topicId] = { ...obj[topicId], videoData: videoData }
                }
                dispatch(
                    getTrainingMediaMiddleware(obj)
                );
                // done();
            })
            .catch((err) => {
                dispatch(getTrainingMediaFail(err));
                message.error("Something went wrong!");
                // done();
            });

        Service({
            method: "post",
            url: API_ROUTES.GET_ALL_CONTENT_DATA,
            data: { ...payload, type: "PDF" },
        })
            .then((res) => {
                const pdfData = get(res, "data.data.list", {});
                if (pdfData.length) {
                    obj[topicId] = { ...obj[topicId], pdfData: pdfData }
                }
                dispatch(
                    getTrainingMediaMiddleware(obj)
                );
                done();
            })
            .catch((err) => {
                dispatch(getTrainingMediaFail(err));
                message.error("Something went wrong!");
                done();
            });
    },
});

const updateJobStatus = createLogic({
    type: UPDATE_TRAINING_STATUS,
    latest: true,
    process({ action }, dispatch, done) {
        const authToken = getItem("token");
        const payload = { authToken: authToken ? authToken : "", ...action.payload };

        Service({
            method: "post",
            url: API_ROUTES.UPDATE_ASSIGN_TRAINING,
            data: { ...payload },
        })
            .then((res) => {
                if(!res.error){
                    message.success("Training status updated successfully.");
                    dispatch(updateTrainingStatusSuccess({...action.payload}));
                }else{
                    message.error("Something went wrong!");
                }
                done();
            })
            .catch((err) => {
                message.error("Something went wrong!");
                done();
            });
    },
});

export default [
    addTrainingLogic,
    addContentLogic,
    getAllTrainingDataLogic,
    getAllChapterDataLogic,
    getAllTrainingSPLogic,
    getUserTrainingLogic,
    getAllMediaContentLogic,
    updateJobStatus,
];
