import { SET_PROFILE_DETAILS } from "./types";

const initialState = {
    personalInfo: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DETAILS:
            return { ...state, personalInfo: action.payload };
        default:
            return { ...state };
    }
};
