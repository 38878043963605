import {
    SET_ACTION_ITEM,
    SET_ROLE,
    GET_NOTIFICATION_COUNT,
    SET_NOTIFICATION_COUNT,
    SET_DEVICE,
    GET_DASHBOARD_DETAILS,
    SET_DASHBOARD_DETAILS,
    RESET_DETAILS,
} from "./types";

export const setRole = (roleId) => ({
    type: SET_ROLE,
    payload: roleId,
});
export const setActionItem = (entity, actionItem) => ({
    type: SET_ACTION_ITEM,
    payload: { entity, actionItem },
});

export const getNotificationCount = () => ({
    type: GET_NOTIFICATION_COUNT,
});

export const setNotificationCount = (payload) => ({
    type: SET_NOTIFICATION_COUNT,
    payload,
});

export const setDevice = (payload) => ({
    type: SET_DEVICE,
    payload,
});

export const resetDetails = () => ({
    type: RESET_DETAILS,
});

export const getDashboardDetails = () => ({
    type: GET_DASHBOARD_DETAILS,
});

export const setDashboardDetails = (payload) => ({
    type: SET_DASHBOARD_DETAILS,
    payload,
});
