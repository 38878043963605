import {
    GET_TRAINING,
    SET_TRAINING,
    SET_ALL_TRAINING,
    SET_ALL_TRAINING_WITHOUT_FILTER,
    SET_ALL_CHAPTERS,
    GET_TRAINING_SERVER_PROVIDER_LISTS_REQUEST,
    GET_TRAINING_SERVER_PROVIDER_LISTS_FAIL,
    GET_TRAINING_SERVER_PROVIDER_LISTS_MIDDLEWARE_SUCCESS,
    SET_ALL_CONTENT_DATA,
    SET_ALL_CONTENT_DATA_LOADING,
    GET_TRAINING_USER_LISTS_REQUEST,
    GET_TRAINING_USER_LISTS_FAIL,
    GET_TRAINING_USER_LISTS_MIDDLEWARE_SUCCESS,
    REMOVE_CONTENT_DATA,
    GET_ALL_CONTENT_DATA_REQUEST,
    GET_ALL_CONTENT_DATA_FAIL,
    GET_ALL_CONTENT_DATA_MIDDLEWARE_SUCCESS,
    TRAINING_STATUS_UPDATE_SUCCESS,
} from "./types";
import get from "lodash/get";

const initialState = {
    training: {},
    trainingView: [],
    allTrainingData: {
        loading: false,
        isError: false,
        errMsg: "",
        data: [],
        lastPage: 1,
    },
    chapters: [],
    contentData: {},
    trainingActive: {},
    trainingPast: { list: [] },
    trainingDataWithoutFilter: [],
    loading: false,
    success: false,
    errorMessage: "",
    status: "",
    serviceProviderList: {},
    contentDataLoaded: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TRAINING:
            return {
                ...state,
                training: action.payload
            };

        case SET_ALL_TRAINING:
            const allTrainingDataList = get(action.payload, "list", []);
            const lastPage = get(action.payload, "lastPage", 1);
            const requestedPage = get(action.payload, "requestedPage", 1);

            return {
                ...state,
                allTrainingData: {
                    ...state.allTrainingData,
                    loading: false,
                    data: allTrainingDataList,
                    lastPage: lastPage,
                },
            };
        case SET_ALL_TRAINING_WITHOUT_FILTER:
            const trainingDataList = get(action.payload, "list", []);

            return {
                ...state,
                trainingDataWithoutFilter: trainingDataList
            };
        case SET_ALL_CHAPTERS:
            const chapters = get(action.payload, "chapters", []);

            return {
                ...state,
                chapters: chapters,
            };
        case GET_TRAINING_SERVER_PROVIDER_LISTS_REQUEST:
            return {
                ...state,
                status: "request",
                loading: true,
                success: false,
                errorMessage: null,
            };

        case GET_TRAINING_SERVER_PROVIDER_LISTS_FAIL:
            return {
                ...state,
                status: "fail",
                loading: false,
                success: false,
                errorMessage: action.payload,
            };

        case GET_TRAINING_SERVER_PROVIDER_LISTS_MIDDLEWARE_SUCCESS:
            return {
                ...state,
                status: "success",
                loading: false,
                success: true,
                errorMessage: null,
                serviceProviderList: action.payload
            };
        case GET_TRAINING_USER_LISTS_REQUEST:
            return {
                ...state,
                status: "request",
                loading: true,
                success: false,
                errorMessage: null,
            };

        case GET_TRAINING_USER_LISTS_FAIL:
            return {
                ...state,
                status: "fail",
                loading: false,
                success: false,
                errorMessage: action.payload,
            };

        case GET_TRAINING_USER_LISTS_MIDDLEWARE_SUCCESS:
            let cloneActive = { ...state.trainingActive }
            let clonePast = { ...state.trainingPast }
            if (action.payload.type === "") {
                cloneActive = action.payload.res
            } else {
                clonePast = action.payload.res
            }

            return {
                ...state,
                status: "success",
                loading: false,
                success: true,
                errorMessage: null,
                trainingActive: cloneActive,
                trainingPast: clonePast,
            };
        case GET_ALL_CONTENT_DATA_REQUEST:
            return {
                ...state,
                status: "request",
                loading: true,
                success: false,
                errorMessage: null,
            };

        case GET_ALL_CONTENT_DATA_FAIL:
            return {
                ...state,
                status: "fail",
                loading: false,
                success: false,
                errorMessage: action.payload,
            };

        case GET_ALL_CONTENT_DATA_MIDDLEWARE_SUCCESS:
            return {
                ...state,
                status: "success",
                loading: false,
                success: true,
                errorMessage: null,
                contentData: action.payload || ""
            };
        case TRAINING_STATUS_UPDATE_SUCCESS:
            let activeData = { ...JSON.parse(JSON.stringify(state.trainingActive)) };
            let pastData = { ...JSON.parse(JSON.stringify(state.trainingPast)) };
            const Id = get(action.payload, "assignedTrainingId", "");
            const type = get(action.payload, "type", false);
            if (type === 'COMPLETED') {
                let num;
                let FilteredData = activeData.list.map((data, index) => {
                    if (data.id === Id) {
                        num = index
                        activeData.list[index].status = type;
                        return data;
                    }
                })
                activeData.list.splice(num, 1);
                pastData.list = [...pastData.list, FilteredData[0]];
            } else {

                activeData.list.forEach((data, index) => {
                    if (data.id === Id) {
                        activeData.list[index].status = type;
                    }
                })

            }
            return {
                ...state,
                trainingActive: activeData,
                trainingPast: pastData
            };

        case SET_ALL_CONTENT_DATA_LOADING:
            return {
                ...state,
                contentDataLoaded: false
            };
        case REMOVE_CONTENT_DATA:
            return {
                ...state,
                contentData: {},
            };
        case SET_ALL_CONTENT_DATA:
            const topicData = get(action.payload, "topicData", {});
            return {
                ...state,
                contentData: topicData,
                contentDataLoaded: true
            };
        default:
            return { ...state };
    }
};
