import { GET_PROFILE_DETAILS, SET_PROFILE_DETAILS } from "./types";

export const getProfileDetails = () => ({
    type: GET_PROFILE_DETAILS,
});

export const setProfileDetails = (payload) => ({
    type: SET_PROFILE_DETAILS,
    payload,
});
